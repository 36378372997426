import {
  Divider,
  FormControl,
  Icon,
  IconButton,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@material-ui/core";
import {
  createStyles,
  lighten,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useState } from "react";
import TableSortHeader from "../table-sort-header";
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: 0,
      minWidth: 100,
      width: "50%",
    },
    optionGroup: {
      background: "white",
    },
    error: {
      backgroundColor: lighten(theme.palette.error.light, 0.75),
    },
    event: {
      backgroundColor: lighten(theme.palette.info.light, 0.9),
    },
    debug: {},
    command: {
      backgroundColor: lighten(theme.palette.warning.light, 0.9),
    },
  })
);

export const TextFieldFilter = (props: any) => {
  const classes = useStyles();
  const { value, onChange, idFilter } = props;
  
  const handleClickShow = () => {
    onChange("", idFilter);
  }

  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleChange =(event: any) => {
    onChange( event.target.value ? event.target.value : "" , idFilter);}
  


  return (
    <FormControl className={classes.formControl}>
 
        <TextField
          id="standard-basic"
          label = "Текст для поиска... "
          InputProps={{ 
            endAdornment: <InputAdornment position="end">
               <Tooltip title="Очистить">
               <IconButton
                  onClick={handleClickShow}
                  onMouseDown={handleMouseDown}
                >
                  <ClearIcon  fontSize="small"/>
                </IconButton>
                </Tooltip>
              </InputAdornment>,
            inputProps: {} }}
          type="string"
          value = {value}
          onChange={handleChange}
        />

    </FormControl>
  );
};
