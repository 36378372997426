import {
  Backdrop,
  CircularProgress,
  Dialog,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import {
  createStyles,
  lighten,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import BlockIcon from "@material-ui/icons/Block";
import EditIcon from "@material-ui/icons/Edit";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useConfirm } from "material-ui-confirm";
import { default as React, useEffect } from "react";
import { AccountFilter } from "../../components/filters/account-filter";
import { NumberFieldFilter } from "../../components/filters/number-field-filter";
import { UserFilter } from "../../components/filters/user-filter";
import DateTime from "../../components/formatters/date-time";
import TableSortHeader from "../../components/table-sort-header";
import { DeviceUpdateContainer as DeviceUpdateForm } from "../../forms/device-update";
import useFilters from "../../hooks/useFilters";
import { Directory, Pagination } from "../../store/Common/CommonModel";
import { Device, DeviceFilter } from "../../store/Device/DeviceModel";
import { Settings } from "../../store/Settings/SettingsModel";
import { ProjectAutoFilter } from "../../components/filters/project-auto-filter";

interface Props {
  accountDirectory: Directory[];
  userDirectory: Directory[];
  onRequestAccountDirectory:()=> void;
  onRequestUserDirectory:()=> void;
  onRequestDeviceList: (filter: DeviceFilter) => void;
  onRequestDeviceBlock: (id: number) => void;
  onRequestDeviceUnblock: (id: number) => void;
  authIsAdmin: boolean;
  devicesLoading: boolean;
  devices: Device[];
  pagination: Pagination;
  filter: DeviceFilter;
  settings: Settings;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    blockedSwitch: {
      float: "right",
    },
    table: {
      minWidth: 700,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    buttonView: {
      margin: theme.spacing(1),
      marginRight: 0,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    lh24px: {
      lineHeight: "24px",
    },
    blocked: {
      backgroundColor: lighten(theme.palette.error.light, 0.75),
    },
    headCell: {
      paddingTop: 5,
      verticalAlign: "top",
      fontWeight: "bold",
    },
    filterCell: {
      paddingTop: 0,
      verticalAlign: "top",
    },
  })
);

export const PageComponent: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const confirm = useConfirm();

  const {
    authIsAdmin,
    devices,
    devicesLoading,
    filter,
    settings,
    pagination,
    onRequestDeviceList,
    onRequestDeviceBlock,
    onRequestDeviceUnblock,
    accountDirectory,
    userDirectory,
    onRequestAccountDirectory,
    onRequestUserDirectory,
  } = props;

  useEffect(() => {
      onRequestDeviceList(filter);
      onRequestAccountDirectory();
      if(authIsAdmin) onRequestUserDirectory();
  }, []);

  const [newFilter, handleChangeOrder, handleChangeValue, handleChangeNumberField] = useFilters(
    filter,
    onRequestDeviceList
  );

  const handleChangeShowBlocked = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    filter.show_blocked = event.target.checked;
    onRequestDeviceList(filter);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    filter.page = newPage;
    onRequestDeviceList(filter);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    filter.page = 0;
    filter.per_page = parseInt(event.target.value, 10);
    onRequestDeviceList(filter);
  };

  // Редактирование устройства

  const [openUpdate, setOpenUpdate] = React.useState<boolean>(false);
  const [updating, setUpdating] = React.useState<Device>();

  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };

  const handleOpenUpdate = (item: Device) => () => {
    setOpenUpdate(true);
    setUpdating(item);
  };

  /* * */

  const [anchorEl, setAnchorEl] =
    React.useState<null | HTMLButtonElement>(null);
  const [openActions, setOpenActions] = React.useState<null | Number>(null);

  const handleActionClick = (
    id: Number,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
    setOpenActions(id);
  };

  const handleActionClose = () => {
    setOpenActions(null);
    setAnchorEl(null);
  };

  const handleBlock = (device: Device) => () => {
    confirm({
      title: "Вы точно хотите заблокировать устройство?",
      description:
        "Сервер будет игнорировать сообщения с заблокированных устройств и отправку команд на них. Так же заблокированные устройства не отображаются в списках устройств.",
    }).then(() => {
      onRequestDeviceBlock(device.id);
    });
  };

  const handleUnblock = (device: Device) => () => {
    onRequestDeviceUnblock(device.id);
  };



  const handleChangeAccountIdField = (id: number) => {
      filter.account_id = id;
      handleChangeValue({
        page: 0,
        account_id: id,
      })
  };

  const handleChangeUserIdField = (id: number) => {
    filter.user_id = id;
    handleChangeValue({
      page: 0,
      user_id: id,
    })
};

  const getRowStyle = (item: Device) => {
    if (item.is_blocked) {
      return classes.blocked;
    }
    return null;
  };

  return (
    <>
      <h1>
        Оборудование
        {/* <FormControlLabel
          className={classes.blockedSwitch}
          control={
            <Switch
              checked={filter.show_blocked}
              onChange={handleChangeShowBlocked}
              name="show_blocked"
              color="primary"
            />
          }
          label="Показывать заблокированные"
        /> */}
      </h1>

      <TableContainer>
        <Table className={classes.table} size="small">
          <TableHead>
          <TableRow>
            <TableCell className={classes.filterCell}  width = "5%"> </TableCell>
            <TableCell className={classes.filterCell} width = "15%">
              {authIsAdmin && (
                  <>
                    <ProjectAutoFilter
                        value={filter.project}
                        onChange={(value: any) =>
                            handleChangeValue({ project: value })
                        }
                    />
                  </>
              )}
            </TableCell>
            <TableCell className={classes.filterCell} width = "10%"> 
              <NumberFieldFilter
                  value={filter.controller}
                  onChange={handleChangeNumberField}
                  idFilter={"controller"}
                  filter={filter}
                />
            </TableCell>
            <TableCell className={classes.filterCell} width = "15%">
            {authIsAdmin && (
            <>
                <UserFilter
                  value={filter.user_id}
                  variants={userDirectory}
                  onChange={handleChangeUserIdField}
                >
                </UserFilter>
            </>
            )}
            </TableCell>
            <TableCell className={classes.filterCell} width = "20%">
              <AccountFilter
                value={filter.account_id}
                variants={accountDirectory}
                userValue={filter.user_id}
                users={userDirectory}
                onChange={handleChangeAccountIdField}
              >
              </AccountFilter>
               
            </TableCell>
            <TableCell className={classes.filterCell}> </TableCell>
            <TableCell className={classes.filterCell} width = "10%"> </TableCell>
            <TableCell className={classes.filterCell} width = "10%"> </TableCell>
          </TableRow>
            <TableRow>
              <TableCell className={classes.headCell}>
                <TableSortHeader
                  id="id"
                  title="ID"
                  order={filter.order}
                  orderBy={filter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell className={classes.headCell}>
                <TableSortHeader
                    id="project"
                    title="Проект"
                    order={filter.order}
                    orderBy={filter.order_by}
                    onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell className={classes.headCell}>
                <TableSortHeader
                  id="controller"
                  title="ID (axiot)"
                  order={filter.order}
                  orderBy={filter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell className={classes.headCell}>
                <TableSortHeader
                  id="user_id"
                  title="Клиент"
                  order={filter.order}
                  orderBy={filter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell className={classes.headCell}>
                <TableSortHeader
                  id="account_id"
                  title="Компания"
                  order={filter.order}
                  orderBy={filter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell className={classes.headCell}>
                <TableSortHeader
                  id={authIsAdmin ? "comment_admin" : "comment_owner"}
                  title="Комментарий"
                  order={filter.order}
                  orderBy={filter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <Hidden only={["xs", "sm", "md"]}>
                <TableCell className={classes.headCell}>
                  <TableSortHeader
                    id="create_datetime"
                    title="Время создания"
                    order={filter.order}
                    orderBy={filter.order_by}
                    onChange={handleChangeOrder}
                  />
                </TableCell>
              </Hidden>
              <TableCell  className={classes.headCell} width="180">
                {authIsAdmin && (
                    <>
                      Действия
                    </>
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {devices &&
              0 < devices.length &&
              devices.map((item) => (
                <TableRow className={getRowStyle(item)} hover key={item.id}>
                  <TableCell align="left">{item.id}</TableCell>
                  <TableCell align="left">{item.project_title}</TableCell>
                  <TableCell align="left">{item.controller}</TableCell>
                  <TableCell align="left">{item.user_name}</TableCell>
                  <TableCell align="left">{item.account_name}</TableCell>
                  <TableCell component="th" scope="row">
                    {item.comment}
                  </TableCell>
                  <Hidden only={["xs", "sm", "md"]}>
                    <TableCell align="center">
                      <DateTime
                        dateFormat={settings.dateFormat}
                        timeFormat={settings.timeFormat}
                        timeZone={settings.timeZone}
                        dateTime={item.create_datetime}
                      />
                    </TableCell>
                  </Hidden>
                  <TableCell align="right">
                    <Hidden only={["xs", "sm", "md"]}>
                      {authIsAdmin && (
                      <>
                        <IconButton onClick={handleOpenUpdate(item)}>
                          <Tooltip title="Редактировать">
                            <EditIcon />
                          </Tooltip>
                        </IconButton>
                      </>
                      )}
                      {authIsAdmin && (
                        <>
                          {!item.is_blocked && (
                            <IconButton
                              color="secondary"
                              onClick={handleBlock(item)}
                              disabled
                            >
                              <Tooltip title="Заблокировать">
                                <BlockIcon />
                              </Tooltip>
                            </IconButton>
                          )}
                          {item.is_blocked && (
                            <IconButton
                              color="primary"
                              onClick={handleUnblock(item)}
                              disabled
                            >
                              <Tooltip title="Разблокировать">
                                <BlockIcon />
                              </Tooltip>
                            </IconButton>
                          )}
                        </>
                      )}
                    </Hidden>

                    <Hidden only={["lg", "xl"]}>
                      <Popper
                        id={"actions-" + item.id}
                        open={openActions === item.id}
                        anchorEl={anchorEl}
                        placement="bottom"
                      >
                        <Menu
                          id={"actions-device-" + item.id}
                          anchorEl={anchorEl}
                          keepMounted
                          open={true}
                          onClick={handleActionClose}
                          onClose={handleActionClose}
                        >
                          <MenuItem
                            color="primary"
                            onClick={handleOpenUpdate(item)}
                          >
                            Редактировать
                          </MenuItem>

                          {authIsAdmin && !item.is_blocked && (
                            <MenuItem onClick={handleBlock(item)} disabled>
                              Заблокировать
                            </MenuItem>
                          )}
                          {authIsAdmin && item.is_blocked && (
                            <MenuItem onClick={handleUnblock(item)} disabled>
                              Разблокировать
                            </MenuItem>
                          )}
                        </Menu>
                      </Popper>

                      <IconButton
                        id={"show-actions-" + item.id}
                        aria-haspopup="true"
                        onClick={(e) => handleActionClick(item.id, e)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Hidden>
                  </TableCell>
                </TableRow>
              ))}

            {(!devices || devices.length === 0) && (
              <>
                <Hidden only={["xs", "sm", "md"]}>
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      Записей нет
                    </TableCell>
                  </TableRow>
                </Hidden>

                <Hidden only={["lg", "xl"]}>
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      Записей нет
                    </TableCell>
                  </TableRow>
                </Hidden>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {(1 < pagination.page_count || 10 < pagination.per_page) && (
        <TablePagination
          component="div"
          count={pagination.total_count}
          page={pagination.page}
          onChangePage={handleChangePage}
          rowsPerPage={pagination.per_page}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="Per page:"
        />
      )}

      <Dialog
        scroll="paper"
        onClose={handleCloseUpdate}
        aria-labelledby="customized-dialog-title"
        open={openUpdate}
        maxWidth="md"
        fullWidth={true}
      >
        <DeviceUpdateForm device={updating} handleClose={handleCloseUpdate} />
      </Dialog>

      <Backdrop className={classes.backdrop} open={devicesLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
