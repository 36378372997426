import { connect } from "react-redux";
import {
  devicesSettingsRequestStartAction
} from "../../store/DeviceSetting/DeviceSettingAction";
import { DeviceSettingFilter } from "../../store/DeviceSetting/DeviceSettingModel";
import { State } from "../../store/reducers";
import { PageComponent } from "./page.component";
import {userDirectoryRequestStartAction} from "../../store/User/UserAction";
import {deviceSettingDirectoryRequestStartAction} from "../../store/DeviceSetting/DeviceSettingAction";

const mapStateToProps = (state: State) => ({
  authIsAdmin: state.auth.isAdmin,
  devicesSettingsLoading: state.device.list.loading,
  devicesSettings: state.deviceSetting.list.items,
  pagination: state.deviceSetting.list.pagination,
  filter: state.deviceSetting.filter,
  settings: state.settings,
  userDirectory: state.user.directory.items,
  deviceSettingDirectory: state.deviceSetting.directory.items,
});

const mapDispatchToProps = (dispatch: any) => ({
  onRequestDeviceSettingList: (filter: DeviceSettingFilter) =>
    dispatch(devicesSettingsRequestStartAction(filter)),
  onRequestUserDirectory: () => dispatch(userDirectoryRequestStartAction()),
  onRequestDeviceSettingDirectory: () => dispatch(deviceSettingDirectoryRequestStartAction()),
});

export const PageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PageComponent);
