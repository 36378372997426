import { connect } from "react-redux";
import { deviceSettingCreateRequestStartAction } from "../../store/DeviceSetting/DeviceSettingAction";
import { DeviceSettingCreateForm } from "../../store/DeviceSetting/DeviceSettingModel";
import { State } from "../../store/reducers";
import { deviceDirectoryRequestStartAction } from "../../store/Device/DeviceAction";
import { deviceSettingDirectoryRequestStartAction } from "../../store/DeviceSetting/DeviceSettingAction";
import { FormComponent } from "./form.component";

const mapStateToProps = (state: State) => ({
  isAdmin: state.auth.isAdmin,
  deviceSettingCreating: state.deviceSetting.creating,
  deviceDirectory: state.device.directory.items,
  deviceSettingDirectory: state.deviceSetting.directory.items,
});

const mapDispatchToProps = (dispatch: any) => ({
  onRequestDeviceSettingCreate: (form: DeviceSettingCreateForm) =>
    dispatch(deviceSettingCreateRequestStartAction(form)),
  onRequestDeviceDirectory: () => dispatch(deviceDirectoryRequestStartAction()),
  onRequestDeviceSettingDirectory: () => dispatch(deviceSettingDirectoryRequestStartAction())
});

export const DeviceSettingCreateContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FormComponent);
