import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { UserFilter } from "../../components/filters/user-filter";
import { ProviderFilter, TaxationFilter, NdsFilter } from "../../components/filters/account-filter";
import { AccountUpdateForm } from "../../store/Account/AccountModel";
import { Directory } from "../../store/Common/CommonModel";

interface Props {
  isAdmin: boolean;
  accountCreating: boolean;
  onRequestAccountUpdate: (id: number, form: AccountUpdateForm) => void;
  userDirectory: Directory[];
  onRequestUserDirectory: () => void;
  providerDirectory: Directory[];
  onRequestProviderDirectory: () => void;
  ndsDirectory: Directory[];
  onRequestNdsDirectory: () => void;
  taxationDirectory: Directory[];
  onRequestTaxationDirectory: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      marginLeft: 0,
      marginRight: 0,
      width: "100%",
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
  })
);

export const FormComponent = (props: any) => {
  const classes = useStyles();
  const {
    isAdmin,
    account,
    handleClose,
    accountCreating,
    onRequestAccountUpdate,
    userDirectory,
    onRequestUserDirectory,
    ndsDirectory,
    onRequestNdsDirectory,
    providerDirectory,
    onRequestProviderDirectory,
    taxationDirectory,
    onRequestTaxationDirectory,
  } = props;

  const updateForm = {
    company: account.company,
    link: account.link,
    service: account.service,
    token: account.token,
    user_id: account.user_id,
    inn: account.inn,
    taxation: account.taxation,
    nds: account.nds,
    provider: account.provider,
    login: account.login,
    password: account.password,
    kkt: account.kkt,
    cashier: account.cashier,
    address: account.address,
    email: account.email
  };

  const [fields, setFields] = React.useState<AccountUpdateForm>(updateForm);

  const handleChangeField =
    (prop: keyof AccountUpdateForm) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFields({ ...fields, [prop]: event.target.value });
    };

  const handleChangeUser = (value: any) => {
    setFields({ ...fields, user_id: value });
  };

  const handleChangeProvider = (value: any) => {
    setFields({ ...fields, provider: value });
  };

  const handleChangeTaxation = (value: any) => {
    setFields({ ...fields, taxation: value });
  };

  const handleChangeNds = (value: any) => {
    setFields({ ...fields, nds: value });
  };

  // ***

  const handleStartUpdate = () => {
    onRequestAccountUpdate(account.id, fields);
    handleClose();
  };
  useEffect(() => {
    if (isAdmin) {
        onRequestUserDirectory();
        onRequestProviderDirectory();
      }
    onRequestTaxationDirectory();
    onRequestNdsDirectory();
    }, []);

  return (
    <>
      <DialogTitle>Создание реквизитов</DialogTitle>
      <DialogContent dividers>
        {isAdmin && (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <UserFilter
                  value={fields.user_id}
                  variants={userDirectory}
                  onChange={handleChangeUser}
                ></UserFilter>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <ProviderFilter
                    value={fields.provider}
                    variants={providerDirectory}
                    onChange={handleChangeProvider}
                ></ProviderFilter>
              </FormControl>
            </Grid>
          </Grid>
        )}

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <TextField
                value={fields.company}
                label="Название компании"
                onChange={handleChangeField("company")}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <TextField
                  value={fields.address}
                  label="Адрес платежа"
                  onChange={handleChangeField("address")}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <TextField
                  value={fields.cashier}
                  label="Кассир"
                  onChange={handleChangeField("cashier")}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <TextField
                value={fields.service}
                label="Название услуги"
                onChange={handleChangeField("service")}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <TextField
                  value={fields.email}
                  label="Email для чеков"
                  onChange={handleChangeField("email")}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
            <TaxationFilter
                value={fields.taxation}
                variants={taxationDirectory}
                onChange={handleChangeTaxation}
            ></TaxationFilter>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <TextField
                value={fields.inn}
                label="ИНН"
                onChange={handleChangeField("inn")}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <NdsFilter
                  value={fields.nds}
                  variants={ndsDirectory}
                  onChange={handleChangeNds}
              ></NdsFilter>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <TextField
                value={fields.link}
                label="Ссылка на API"
                onChange={handleChangeField("link")}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <TextField
                value={fields.token}
                label="Ключ (для Аренды Касс)"
                onChange={handleChangeField("token")}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <TextField
                  value={fields.login}
                  label="Логин АПИ (для атол и эвотор)"
                  onChange={handleChangeField("login")}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <TextField
                  value={fields.password}
                  label="Пароль API (для атол и эвотор)"
                  onChange={handleChangeField("password")}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <TextField
                  value={fields.kkt}
                  label="Имя KKT (для атол и эвотор)"
                  onChange={handleChangeField("kkt")}
              />
            </FormControl>
          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions>
        {!accountCreating && (
          <>
            <Button onClick={handleClose} color="primary">
              Отмена
            </Button>
            <Button
              onClick={handleStartUpdate}
              variant="contained"
              color="primary"
            >
              Сохранить
            </Button>
          </>
        )}

        {accountCreating && <CircularProgress />}
      </DialogActions>
    </>
  );
};
