import { call, put, select, takeEvery } from "redux-saga/effects";
import { BaseAction } from "../Common/CommonModel";
import { State } from "../reducers";
import { enqueueSnackbar } from "../Snackbar/SnackbarAction";
import {
  deviceSettingCreateRequestCompletedAction,
  deviceSettingCreateRequestFailedAction,
  deviceSettingDirectoryRequestCompletedAction,
  deviceSettingDirectoryRequestFailedAction,
  devicesSettingsRequestCompletedAction,
  devicesSettingsRequestFailedAction,
  devicesSettingsRequestStartAction,
  deviceSettingUpdateRequestCompletedAction,
  deviceSettingUpdateRequestFailedAction,
} from "./DeviceSettingAction";
import {
  getDeviceSettingDirectory,
  getDevicesSettingsList,
  postDeviceSettingUpdate,
  postDeviceSettingCreate,
} from "./DeviceSettingApis";
import * as types from "./DeviceSettingTypes";
import {postAccountCreate} from "../Account/AccountApis";
import {
  accountCreateRequestCompletedAction,
  accountCreateRequestFailedAction,
  accountsRequestStartAction
} from "../Account/AccountAction";

export function* watchDeviceSettingRequestStart() {
  yield takeEvery(types.GET_DEVICE_SETTING_LIST_START, requestDeviceSettingList);
  yield takeEvery(types.GET_DEVICE_SETTING_DIRECTORY_START, requestDeviceSettingDirectory);
  yield takeEvery(types.CREATE_DEVICE_SETTING_START, requestCreateDeviceSetting);
  yield takeEvery(types.UPDATE_DEVICE_SETTING_START, requestUpdateDeviceSetting);
}

const getFilter = (state: State) => state.deviceSetting.filter;

function* requestDeviceSettingList(action: BaseAction) {
  try {
    const prjId = 0;
    const response = yield call(getDevicesSettingsList, prjId, {
      ...action.payload,
      filter: {
        ...action.payload.filter,
        page: action.payload.filter.page + 1,
      },
    });

    if (response.status != 200) {
      throw { message: "Неизвестная ошибка" };
    }

    yield put(
      devicesSettingsRequestCompletedAction(response.data["list"], {
        ...response.data["pagination"],
        page: response.data["pagination"].page - 1,
      })
    );
  } catch (e) {
    yield put(devicesSettingsRequestFailedAction());

    const notification = {
      message: "Не удалось получить список устройств (" + e.message + ")",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    };

    yield put(enqueueSnackbar(notification));
  }
}

// ***

function* requestDeviceSettingDirectory(action: BaseAction) {
  try {
    const prjId = 0;
    const response = yield call(getDeviceSettingDirectory, prjId, action.payload);

    if (response.status != 200) {
      throw { message: "Неизвестная ошибка" };
    }

    yield put(deviceSettingDirectoryRequestCompletedAction(response.data));
  } catch (e) {
    yield put(deviceSettingDirectoryRequestFailedAction());

    const notification = {
      message: "Не удалось получить справочник устройств (" + e.message + ")",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    };

    yield put(enqueueSnackbar(notification));
  }
}

// ***

function* requestUpdateDeviceSetting(action: BaseAction) {
  try {
    const prjId = 0;
    const response = yield call(postDeviceSettingUpdate, prjId, action.payload);

    if (response.status != 200) {
      throw { message: "Неизвестная ошибка" };
    }

    yield put(deviceSettingUpdateRequestCompletedAction());
  } catch (e) {
    yield put(deviceSettingUpdateRequestFailedAction());

    const notification = {
      message: "Не удалось создать реквизиты (" + e.message + ")",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    };

    yield put(enqueueSnackbar(notification));
  }

  const filter = yield select(getFilter);
  yield put(devicesSettingsRequestStartAction(filter));
}


function* requestCreateDeviceSetting(action: BaseAction) {
  try {
    const prjId = 0;
    const response = yield call(postDeviceSettingCreate, prjId, action.payload);

    if (response.status != 200) {
      throw { message: "Неизвестная ошибка" };
    }

    yield put(deviceSettingCreateRequestCompletedAction());
  } catch (e) {
    yield put(deviceSettingCreateRequestFailedAction());

    const notification = {
      message: "Не удалось создать настройку (" + e.message + ")",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    };

    yield put(enqueueSnackbar(notification));
  }

  const filter = yield select(getFilter);
  yield put(devicesSettingsRequestStartAction(filter));
}