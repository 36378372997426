export const GET_DEVICE_SETTING_LIST_START = "GET_DEVICE_SETTING_LIST - Start";
export const GET_DEVICE_SETTING_LIST_COMPLETED = "GET_DEVICE_SETTING_LIST - Completed";
export const GET_DEVICE_SETTING_LIST_FAILED = "GET_DEVICE_SETTING_LIST - Failed";
export const GET_DEVICE_SETTING_DIRECTORY_START = "GET_DEVICE_SETTING_DIRECTORY - Start";
export const GET_DEVICE_SETTING_DIRECTORY_COMPLETED =
  "GET_DEVICE_SETTING_DIRECTORY - Completed";
export const GET_DEVICE_SETTING_DIRECTORY_FAILED = "GET_DEVICE_SETTING_DIRECTORY - Failed";

export const BLOCK_DEVICE_SETTING_START = "BLOCK_DEVICE_SETTING - Start";
export const BLOCK_DEVICE_SETTING_COMPLETED = "BLOCK_DEVICE_SETTING - Completed";
export const BLOCK_DEVICE_SETTING_FAILED = "BLOCK_DEVICE_SETTING - Failed";

export const UPDATE_DEVICE_SETTING_START = "UPDATE_DEVICE_SETTING - Start";
export const UPDATE_DEVICE_SETTING_COMPLETED = "UPDATE_DEVICE_SETTING - Completed";
export const UPDATE_DEVICE_SETTING_FAILED = "UPDATE_DEVICE_SETTING - Failed";

export const CREATE_DEVICE_SETTING_START = "CREATE_DEVICE_SETTING - Start";
export const CREATE_DEVICE_SETTING_COMPLETED = "CREATE_DEVICE_SETTING - Completed";
export const CREATE_DEVICE_SETTING_FAILED = "CREATE_DEVICE_SETTING - Failed";
