import { call, put, select, takeEvery } from "redux-saga/effects";
import { BaseAction } from "../Common/CommonModel";
import { State } from "../reducers";
import { enqueueSnackbar } from "../Snackbar/SnackbarAction";
import {
  deviceDirectoryRequestCompletedAction,
  deviceDirectoryRequestFailedAction,
  devicesRequestCompletedAction,
  devicesRequestFailedAction,
  devicesRequestStartAction,
  deviceUpdateRequestCompletedAction,
  deviceUpdateRequestFailedAction,
} from "./DeviceAction";
import {
  getDeviceDirectory,
  getDevicesList,
  postDeviceUpdate,
} from "./DeviceApis";
import * as types from "./DeviceTypes";

export function* watchDeviceRequestStart() {
  yield takeEvery(types.GET_DEVICE_LIST_START, requestDeviceList);
  yield takeEvery(types.GET_DEVICE_DIRECTORY_START, requestDeviceDirectory);
  yield takeEvery(types.UPDATE_DEVICE_START, requestUpdateDevice);
}

const getFilter = (state: State) => state.device.filter;

function* requestDeviceList(action: BaseAction) {
  try {
    const prjId = 0;
    const response = yield call(getDevicesList, prjId, {
      ...action.payload,
      filter: {
        ...action.payload.filter,
        page: action.payload.filter.page + 1,
      },
    });

    if (response.status != 200) {
      throw { message: "Неизвестная ошибка" };
    }

    yield put(
      devicesRequestCompletedAction(response.data["list"], {
        ...response.data["pagination"],
        page: response.data["pagination"].page - 1,
      })
    );
  } catch (e) {
    yield put(devicesRequestFailedAction());

    const notification = {
      message: "Не удалось получить список устройств (" + e.message + ")",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    };

    yield put(enqueueSnackbar(notification));
  }
}

// ***

function* requestDeviceDirectory(action: BaseAction) {
  try {
    const prjId = 0;
    const response = yield call(getDeviceDirectory, prjId, action.payload);

    if (response.status != 200) {
      throw { message: "Неизвестная ошибка" };
    }

    yield put(deviceDirectoryRequestCompletedAction(response.data));
  } catch (e) {
    yield put(deviceDirectoryRequestFailedAction());

    const notification = {
      message: "Не удалось получить справочник устройств (" + e.message + ")",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    };

    yield put(enqueueSnackbar(notification));
  }
}

// ***

function* requestUpdateDevice(action: BaseAction) {
  try {
    const prjId = 0;
    const response = yield call(postDeviceUpdate, prjId, action.payload);

    if (response.status != 200) {
      throw { message: "Неизвестная ошибка" };
    }

    yield put(deviceUpdateRequestCompletedAction());
  } catch (e) {
    yield put(deviceUpdateRequestFailedAction());

    const notification = {
      message: "Не удалось создать реквизиты (" + e.message + ")",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    };

    yield put(enqueueSnackbar(notification));
  }

  const filter = yield select(getFilter);
  yield put(devicesRequestStartAction(filter));
}
