import {
  AppBar,
  ClickAwayListener,
  Divider,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import React from "react";
import { NavState } from "../../reducers/nav.reducer";
import { AuthState } from "../../store/Auth/AuthReducer";

interface Props {
  onSignOutAction: () => void;
  onToggleNav: (isOpen: boolean) => void;
  nav: NavState;
  auth: AuthState;
}

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    profileMenu: {
      minWidth: 120,
    },
    grow: {
      flexGrow: 1,
    },
    sectionUser: {
      // display: 'flex',
    },
    title: {
      // flexGrow: 1,
    },
    hide: {
      display: "none",
    },
  })
);

export const HeaderComponent: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const { onSignOutAction, onToggleNav, nav, auth } = props;

  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const [profileMenuOpen, setProfileMenuOpen] = React.useState<boolean>(false);

  const handleProfileMenuOpen = () => {
    setProfileMenuOpen((prevProfileMenuOpen) => !prevProfileMenuOpen);
  };

  const profileMenuClose = () => {
    setProfileMenuOpen(false);
  };

  const handleSignOut = () => {
    onSignOutAction();
  };

  return (
    <>
      <div className={classes.root} id="header">
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: nav.isOpen,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Open menu"
              onClick={() => onToggleNav(true)}
              edge="start"
              className={clsx(
                classes.menuButton,
                (nav.isOpen || !auth.isAuthenticated) && classes.hide
              )}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title} noWrap>
              uChecker.ru
            </Typography>

            <div className={classes.grow} />

            <div className={classes.sectionUser}>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                color="inherit"
                ref={anchorRef}
                aria-controls={profileMenuOpen ? "menu-list-grow" : undefined}
                onClick={handleProfileMenuOpen}
              >
                <AccountCircle />
              </IconButton>

              <Popper
                open={profileMenuOpen}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper className={classes.profileMenu}>
                      <ClickAwayListener onClickAway={profileMenuClose}>
                        <MenuList
                          autoFocusItem={profileMenuOpen}
                          id="menu-list-grow"
                        >
                          <MenuItem onClick={profileMenuClose} disabled>
                            Профиль
                          </MenuItem>
                          <MenuItem onClick={profileMenuClose} disabled>
                            Настройки
                          </MenuItem>

                          <Divider />

                          <MenuItem onClick={handleSignOut}>Выход</MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    </>
  );
};
