import { BaseAction, Directory, Pagination } from "../Common/CommonModel";
import { Device, DeviceFilter } from "./DeviceModel";
import * as types from "./DeviceTypes";

export type DeviceState = {
  list: {
    loading: boolean;
    items: Device[];
    pagination: Pagination;
  };
  directory: {
    loading: boolean;
    items: Directory[];
  };
  updating: boolean;
  filter: DeviceFilter;
};

const defaultDevicePagination = {
  page: 0,
  page_count: 0,
  per_page: 10,
  total_count: 0,
};

export const defaultDeviceState: DeviceState = {
  list: {
    loading: false,
    items: [],
    pagination: defaultDevicePagination,
  },
  directory: {
    loading: false,
    items: [],
  },
  updating: false,
  filter: {
    page: 0,
    per_page: defaultDevicePagination.per_page,
    order_by: "id",
    order: "asc",
    show_blocked: false,
    account_id: null,
    project: null,
    user_id: null,
    controller: -1
  },
};

export const deviceReducer = (
  state: DeviceState = defaultDeviceState,
  action: BaseAction
) => {
  switch (action.type) {
    case types.GET_DEVICE_LIST_START:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };

    case types.GET_DEVICE_LIST_COMPLETED:
      return {
        ...state,
        list: {
          loading: false,
          items: action.payload.items,
          pagination: action.payload.pagination,
        },
      };

    case types.GET_DEVICE_LIST_FAILED:
      return {
        ...state,
        list: {
          loading: false,
          items: [],
          pagination: defaultDevicePagination,
        },
      };

    // ***

    case types.GET_DEVICE_DIRECTORY_START:
      return {
        ...state,
        directory: {
          items: [],
          loading: true,
        },
      };

    case types.GET_DEVICE_DIRECTORY_COMPLETED:
      return {
        ...state,
        directory: {
          loading: false,
          items: action.payload.items,
        },
      };

    case types.GET_DEVICE_DIRECTORY_FAILED:
      return {
        ...state,
        directory: {
          loading: false,
          items: [],
        },
      };

    // ***

    case types.UPDATE_DEVICE_START:
      return {
        ...state,
        updating: true,
      };

    case types.UPDATE_DEVICE_COMPLETED:
      return {
        ...state,
        updating: false,
      };

    case types.UPDATE_DEVICE_FAILED:
      return {
        ...state,
        updating: false,
      };
  }

  return state;
};
