import { connect } from "react-redux";
import { accountDirectoryRequestStartAction } from "../../store/Account/AccountAction";
import { userDirectoryRequestStartAction } from "../../store/User/UserAction";
import {
  deviceBlockStartAction,
  devicesRequestStartAction,
  deviceUnblockStartAction,
} from "../../store/Device/DeviceAction";
import { DeviceFilter } from "../../store/Device/DeviceModel";
import { State } from "../../store/reducers";
import { PageComponent } from "./page.component";

const mapStateToProps = (state: State) => ({
  authIsAdmin: state.auth.isAdmin,
  devicesLoading: state.device.list.loading,
  devices: state.device.list.items,
  pagination: state.device.list.pagination,
  filter: state.device.filter,
  settings: state.settings,
  accountDirectory: state.account.directory.items,
  userDirectory: state.user.directory.items,
});

const mapDispatchToProps = (dispatch: any) => ({
  onRequestDeviceList: (filter: DeviceFilter) =>
    dispatch(devicesRequestStartAction(filter)),
  onRequestDeviceBlock: (id: number) => dispatch(deviceBlockStartAction(id)),
  onRequestDeviceUnblock: (id: number) =>
    dispatch(deviceUnblockStartAction(id)),
  onRequestAccountDirectory: () => dispatch(accountDirectoryRequestStartAction()), 
  onRequestUserDirectory: () => dispatch(userDirectoryRequestStartAction()), 
});

export const PageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PageComponent);
