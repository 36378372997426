export const GET_USER_LIST_START = "GET_USER_LIST - Start";
export const GET_USER_LIST_COMPLETED = "GET_USER_LIST - Completed";
export const GET_USER_LIST_FAILED = "GET_USER_LIST - Failed";

export const GET_USER_DIRECTORY_START = "GET_USER_DIRECTORY - Start";
export const GET_USER_DIRECTORY_COMPLETED = "GET_USER_DIRECTORY - Completed";
export const GET_USER_DIRECTORY_FAILED = "GET_USER_DIRECTORY - Failed";

export const CREATE_USER_START = "CREATE_USER - Start";
export const CREATE_USER_COMPLETED = "CREATE_USER - Completed";
export const CREATE_USER_FAILED = "CREATE_USER - Failed";

export const UPDATE_USER_START = "UPDATE_USER - Start";
export const UPDATE_USER_COMPLETED = "UPDATE_USER - Completed";
export const UPDATE_USER_FAILED = "UPDATE_USER - Failed";
