import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { DeviceSettingUpdateForm } from "../../store/DeviceSetting/DeviceSettingModel";

interface Props {
  isAdmin: boolean;
  deviceSettingUpdating: boolean;
  onRequestDeviceSettingUpdate: (form: DeviceSettingUpdateForm) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      marginLeft: 0,
      marginRight: 0,
      width: "100%",
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
  })
);

export const FormComponent = (props: any) => {
  const classes = useStyles();
  const {
    isAdmin,
    deviceSetting,
    handleClose,
    onRequestDeviceSettingUpdate
  } = props;

  const updateForm = {
    device_id: deviceSetting.device_id,
    key: deviceSetting.key,
    value: deviceSetting.value,
    key_title: deviceSetting.key_title,
    controller: deviceSetting.controller
  };

  const [fields, setFields] = React.useState<DeviceSettingUpdateForm>(updateForm);

  const handleChangeField =
    (prop: keyof DeviceSettingUpdateForm) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFields({ ...fields, [prop]: event.target.value });
    };

  // ***

  const handleStartCreate = () => {
    onRequestDeviceSettingUpdate(deviceSetting.id, fields);
    handleClose();
  };

  return (
    <>
      <DialogTitle>Редактирование настройки оборудования</DialogTitle>
      <DialogContent dividers>

          <h2>{fields.key_title} контроллер #{fields.controller}</h2>

          <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                  <TextField
                      value={fields.value}
                      label="Значение"
                      onChange={handleChangeField("value")}
                  />
              </FormControl>
          </Grid>


      </DialogContent>
      <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleStartCreate}
              variant="contained"
              color="primary"
            >
              Сохранить
            </Button>

      </DialogActions>
    </>
  );
};
