import { connect } from "react-redux";
import { publicReceiptRequestStartAction } from "../../store/Receipt/ReceiptAction";
import { State } from "../../store/reducers";
import { PageComponent } from "./page.component";

const mapStateToProps = (state: State) => ({
  receiptLoading: state.receipt.public.loading,
  receiptStatus: state.receipt.public.status,
  receipt: state.receipt.public.item,
  settings: state.settings,
});

const mapDispatchToProps = (dispatch: any) => ({
  onRequestReceiptItem: (deviceID: number, checkID: number) =>
    dispatch(publicReceiptRequestStartAction(deviceID, checkID)),
});

export const PageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PageComponent);
