import { applyMiddleware, compose, createStore } from "redux";
import { load, save } from "redux-localstorage-simple";
import createSagaMiddleware from "redux-saga";
import { defaultAccountState } from "./Account/AccountReducer";
import { defaultDeviceState } from "./Device/DeviceReducer";
import { defaultDeviceSettingState } from "./DeviceSetting/DeviceSettingReducer";
import { defaultReceiptState } from "./Receipt/ReceiptReducer";
import { rootReducer } from "./reducers";
import { rootSaga } from "./sagas";
import { defaultUserState } from "./User/UserReducer";

const sagaMiddleware = createSagaMiddleware();

const composeEnhancer =
    (process.env.NODE_ENV !== "production" &&
        window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"]) ||
    compose;

export const store = createStore(
    rootReducer,
    load({
        namespace: "axiot",
        states: [
            "auth.isAuthenticated",
            "auth.isAdmin",
            "auth.accessToken",
            "settings",
            "account.filter.order",
            "account.filter.order_by",
            "account.filter.per_page",
            "device.filter.order",
            "device.filter.order_by",
            "device.filter.per_page",
            "receipt.filter.order",
            "receipt.filter.order_by",
            "receipt.filter.per_page",
            "user.filter.order",
            "user.filter.order_by",
            "user.filter.per_page",
        ],
        preloadedState: {
            account:defaultAccountState,
            device: defaultDeviceState,
            deviceSetting: defaultDeviceSettingState,
            receipt: defaultReceiptState,
            user: defaultUserState,
        },
    }),
    composeEnhancer(
        applyMiddleware(
            save({
                namespace: "axiot",
                states: [
                    "auth.isAuthenticated",
                    "auth.isAdmin",
                    "auth.accessToken",
                    "settings",
                    "account.filter.order",
                    "account.filter.order_by",
                    "account.filter.per_page",
                    "device.filter.order",
                    "device.filter.order_by",
                    "device.filter.per_page",
                    "receipt.filter.order",
                    "receipt.filter.order_by",
                    "receipt.filter.per_page",
                    "user.filter.order",
                    "user.filter.order_by",
                    "user.filter.per_page",
                ],
                ignoreStates: ["snackbar", "nav"],
            }),
            sagaMiddleware
        )
    )
);

sagaMiddleware.run(rootSaga);
