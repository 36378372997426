import { all, call, put, takeEvery } from "redux-saga/effects";
import { BaseAction } from "../Common/CommonModel";
import { enqueueSnackbar } from "../Snackbar/SnackbarAction";
import { signInCompletedAction, signInFailedAction } from "./AuthAction";
import { apiSignIn } from "./AuthApis";
import * as types from './AuthTypes';

export function* watchAuthStart() {
    yield takeEvery(types.SIGN_IN_START, signIn);
}

function* signIn(action: BaseAction) {
    try {
        const { resp } = yield all({
            resp: call(apiSignIn, action.payload),
        });
        
        yield put(signInCompletedAction(resp.data.token, resp.data.is_admin));
    } catch (e) {
        yield put(signInFailedAction());

        const notification = {
            message: e.message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
            },
        };

        yield put(enqueueSnackbar(notification));
    }
}
