import { connect } from "react-redux";
import { accountDirectoryRequestStartAction } from "../../store/Account/AccountAction";
import { deviceUpdateRequestStartAction } from "../../store/Device/DeviceAction";
import { DeviceUpdateForm } from "../../store/Device/DeviceModel";
import { State } from "../../store/reducers";
import { userDirectoryRequestStartAction } from "../../store/User/UserAction";
import { FormComponent } from "./form.component";

const mapStateToProps = (state: State) => ({
  isAdmin: state.auth.isAdmin,
  deviceUpdating: state.device.updating,
  userDirectory: state.user.directory.items,
  accountDirectory: state.account.directory.items,
});

const mapDispatchToProps = (dispatch: any) => ({
  onRequestDeviceUpdate: (id: number, form: DeviceUpdateForm) =>
    dispatch(deviceUpdateRequestStartAction(id, form)),
  onRequestUserDirectory: () => dispatch(userDirectoryRequestStartAction()),
  onRequestAccountDirectory: () =>
    dispatch(accountDirectoryRequestStartAction()),
});

export const DeviceUpdateContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FormComponent);
