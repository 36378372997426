import agentInstance from "../../api/agent";
import { Directory } from "../Common/CommonModel";
import { Device } from "./DeviceModel";

export const getDeviceDirectory = (
  prjId: Number,
  params: {}
): Promise<Directory[]> => {
  return new Promise<Directory[]>((resolve) => {
    resolve(agentInstance(prjId).get(`devices/dir`));
  });
};

export const getDevicesList = (
  prjId: Number,
  params: {}
): Promise<Device[]> => {
  return new Promise<Device[]>((resolve) => {
    resolve(
      agentInstance(prjId).get(`devices`, {
        params: params["filter"],
      })
    );
  });
};

export const postDeviceUpdate = (prjId: Number, params: {}): Promise<any> => {
  return new Promise<Device[]>((resolve) => {
    resolve(
      agentInstance(prjId).post(`devices/` + params["id"], params["form"])
    );
  });
};

export const apiDeviceBlock = (
  prjId: Number,
  params: {}
): Promise<Device[]> => {
  return new Promise<Device[]>((resolve) => {
    resolve(agentInstance(prjId).patch(`devices/` + params["id"] + `/block/`));
  });
};
export const apiDeviceUnblock = (
  prjId: Number,
  params: {}
): Promise<Device[]> => {
  return new Promise<Device[]>((resolve) => {
    resolve(
      agentInstance(prjId).patch(`devices/` + params["id"] + `/unblock/`)
    );
  });
};
