export const RECEIPT_STATUS_NEW = 1;
export const RECEIPT_STATUS_PENDING = 2;
export const RECEIPT_STATUS_PENDING_SEND = 6;
export const RECEIPT_STATUS_SUCCESS = 3;
export const RECEIPT_STATUS_ERROR = 4;
export const RECEIPT_STATUS_SCIPPED = 5;
export const RECEIPT_STATUS_WARNING = 8;
export const RECEIPT_STATUS_RETURN = 7;
export const RECEIPT_STATUS_FORCE_NEW = 9;

export const PUBLIC_RECEIPT_STATUS_UNDEFINED = 0;
export const PUBLIC_RECEIPT_STATUS_PENDING = 1;
export const PUBLIC_RECEIPT_STATUS_READY = 2;
