import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { default as React, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  PUBLIC_RECEIPT_STATUS_PENDING,
  PUBLIC_RECEIPT_STATUS_READY,
  PUBLIC_RECEIPT_STATUS_UNDEFINED,
} from "../../store/Receipt/ReceiptConstant";
import { Receipt } from "../../store/Receipt/ReceiptModel";

interface Props {
  receiptLoading: boolean;
  receiptStatus: number;
  receipt: Receipt;
  onRequestReceiptItem: (deviceID: number, checkID: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundImage: 'url("/images/2.jpg")',
      backgroundPosition: "left -74px",
      fontFamily: '"Roboto", Arial, sans-serif',
      height: "100%",
      width: "100%",
      position: "absolute",
    },
    logo: {
      paddingTop: "20px",
      textAlign: "center",
      paddingBottom: "20px",
      visibility: "hidden"
    },
    hole: {
      width: "360px",
      height: "15px",
      backgroundColor: "black",
      borderRadius: "15px",
      position: "relative",
      margin: "0 auto",
      borderBottom: "2px solid #ffffff",
    },
    receipt_holder: {
      position: "relative",
      overflow: "hidden",
      marginTop: "-7px",
      height: "500px",
    },
    no_receipt: {
      padding: "80px 0",
      textAlign: "center",
    },
    receipt: {
      width: "320px",
      backgroundImage:
        "linear-gradient(180deg,#333333 0%,#d5d4d0 2%,#eeeeec 31%,#ffffff 74%,#ffffff 94%,#d5d4d0 100%\n  )",
      position: "relative",
      margin: "0 auto",
      padding: "20px 15px",
      boxSizing: "border-box",
      fontSize: "14px",
      lineHeight: "14px",
      boxShadow: "0 -3px 27px -5px rgb(0 0 0)",
      fontFamily: "monospace",
      background:
        "linear-gradient(180deg,#333333 0%,#d5d4d0 2%,#eeeeec 31%,#ffffff 74%,#ffffff 94%,#d5d4d0 100%)",
      marginTop: "-500px",
      transitionTimingFunction: "cubic-bezier(0.25, 0.75, 0.5, 1.04)",
      transitionDuration: "2s",
    },
    receipt__header: {
      fontSize: "12px",
      lineHeight: "12px",
      textAlign: "center",
    },
    receipt__body: {},
    receipt__line: {
      textAlign: "center",
      padding: "5px 0",
    },
    receipt__title: {
      backgroundColor: "#d5d4d0",
      fontWeight: "bold",
      textAlign: "center",
      marginTop: "10px",
      marginBottom: "5px",
    },
    receipt__body_table: {
      width: "100%",
    },
    receipt__body_table_tr_td_first_child: {
      width: "215px",
    },
    receipt__body_table_tr_td_last_child: {
      textAlign: "right",
    },
    receipt__body_table_tr_td_bold_left: {
      borderTop: "1px solid #999999",
      fontWeight: "bold",
      textAlign: "left",
    },
    receipt__body_table_tr_td_bold_right: {
      borderTop: "1px solid #999999",
      fontWeight: "bold",
      textAlign: "right",
    },
    receipt__body_table_tr_td_small_left: {
      fontSize: "12px",
      lineHeight: "12px",
      textAlign: "left",
    },
    receipt__body_table_tr_td_small_right: {
      fontSize: "12px",
      lineHeight: "12px",
      textAlign: "right",
    },
    receipt__preheader: {
      width: "100%",
      position: "relative",
    },
    receipt__prefooter: {
      textAlign: "center",
      fontSize: "12px",
      lineHeight: "12px",
    },
    receipt__prefooter__qrcode: {
      padding: "10px",
    },
    receipt__prefooter__qrcode_imgcont: {
      minWidth: "125px",
      minHeight: "125px",
    },
    receipt__prefooter__qrcode_img: {
      maxWidth: "125px",
    },
    receipt__footer: {
      "&::before": {
        content: '""',
        position: "absolute",
        left: "0",
        bottom: "-5px",
        right: "0",
        height: "3px",
        borderTop: "2px dashed #d5d4d0",
      },
      "&::after": {
        content: '""',
        position: "absolute",
        left: "0",
        bottom: "-4px",
        right: "0",
        height: "3px",
        borderTop: "1px dashed #d5d4d0",
      },
    },
    buttons: {
      textAlign: "center",
      marginBottom: "20px",
    },
    button: {
      alignSelf: "center",
      border: "solid 0.625em transparent",
      padding: "3px",
      width: "115px",
      height: "115px",
      fontSize: "14px",
      borderRadius: "100%",
      textShadow: "0 0 1px currentColor, 0 0 2px dimgrey",
      cursor: "pointer",
      boxShadow:
        "inset 0 0 0 1px #c9c9c9, inset 0 1px 2px rgb(255 255 255 / 50%),inset 0 -1px 2px rgb(0 0 0 / 50%)",
      background:
        "conic-gradient(    #d7d7d7,    #c3c3c3,    #cccccc,    #c6c6c6,    #d3d3d3,    #d8d8d8,    #d5d5d5,    #d8d8d8,    #d3d3d3,    #c5c5c5,    #c0c0c0,    #bfbfbf,    #d0d0d0,    #d9d9d9,    #d1d1d1,    #c5c5c5,    #c8c8c8,    #d7d7d7,    #d5d5d5,    #cdcdcd,    #c4c4c4,    #d9d9d9,    #cecece,    #c5c5c5,    #c5c5c5,    #cdcdcd,    #d8d8d8,    #d9d9d9,    #d7d7d7  )  content-box,linear-gradient(#d4d4d4, #d4d4d4) padding-box,radial-gradient(rgba(120, 120, 120, 0), rgba(120, 120, 120, 0) 70%) 50%  bottom/80% 0.46875em no-repeat border-box",
      "&:hover": {
        transform: "scale(0.99)",
        background:
          "conic-gradient(    #d3d3d3,    #c5c5c5,    #c5c5c5,    #cdcdcd,    #d8d8d8,    #d9d9d9,    #d7d7d7,    #c0c0c0,    #d7d7d7,    #d5d5d5,    #cdcdcd,    #c4c4c4,    #d9d9d9,    #cecece,    #c5c5c5,    #d3d3d3  )  content-box,linear-gradient(#d4d4d4, #d4d4d4) padding-box,radial-gradient(rgba(120, 120, 120, 0), rgba(120, 120, 120, 0) 70%) 50%  bottom/80% 0.46875em no-repeat border-box",
      },
      "&:active": {
        transform: "scale(0.95)",
        background:
          "conic-gradient(    #c0c0c0,    #d7d7d7,    #d5d5d5,    #cdcdcd,    #c4c4c4,    #d9d9d9,    #cecece,    #c5c5c5,    #d3d3d3,    #d3d3d3,    #c5c5c5,    #c5c5c5,    #cdcdcd,    #d8d8d8,    #d9d9d9,    #d7d7d7,    #c0c0c0  )  content-box,linear-gradient(#d4d4d4, #d4d4d4) padding-box,radial-gradient(rgba(120, 120, 120, 0), rgba(120, 120, 120, 0) 70%) 50%  bottom/80% 0.46875em no-repeat border-box",
      },
    },
    copyright: {
      paddingTop: "20px",
      textAlign: "center",
      width: "280px",
      margin: "0 auto",
      color: "#ffffff",
      textShadow: "1px 2px 3px black",
      fontSize: "14px",
    },
    copyright_a: {
      color: "#ffffff",
    },
    [`@media print`]: {
      root: {
        backgroundImage: "none",
        backgroundPosition: "left -74px",
        fontFamily: '"Roboto", Arial, sans-serif',
        height: "100%",
        width: "100%",
        position: "relative",
      },
      receipt_holder: {
        position: "relative",
        overflow: "hidden",
        marginTop: "50px",
        height: "auto",
      },
      logo: {
        display: "none",
      },
      buttons: {
        display: "none",
      },
      hole: {
        display: "none",
      },
      receipt: {
        width: "60%",
        fontFamily: "monospace",
        textAlign: "center",
        marginTop: "100px",
        boxShadow: "none",
        border: "1px solid black",
      },
      receipt__body_table: {
        textAlign: "left",
        width: "100%",
      },
      receipt__title: {
        backgroundColor: "#d5d4d0 !important",
      },
      receipt__line: {
        padding: "5px 0",
        marginBottom: "10px",
      },
      receipt__prefooter: {
        marginTop: "20px",
      },
      receipt__body_table_tr_td_bold: {
        borderTop: "1px solid #999999",
        fontWeight: "bold",
      },
      receipt__prefooter__qrcode_img: {
        maxWidth: "125px",
      },
      copyright: {
        display: "none",
      },
    },
  })
);

export const PageComponent: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const { deviceID, checkID } = useParams();
  const { receipt, receiptLoading, receiptStatus, onRequestReceiptItem } =
    props;

  useEffect(() => {
    onRequestReceiptItem(deviceID, checkID);
  }, []);
  if (receiptStatus === PUBLIC_RECEIPT_STATUS_READY && receipt.link != "") {
      window.location.replace(receipt.link);
      window.location.href = receipt.link;
  }
  return (
    <div className={classes.root}>
      <div className={classes.logo}>
        <a href="https://uchecker.ru/">
          <img
            alt="Решение по ФЗ-54 для автомоек самообслуживания"
            width="120"
            src="https://150bar.ru/wp-content/uploads/2020/06/logo-min.png"
          />
        </a>
      </div>
      <div className={classes.hole}>
        <></>
      </div>
      <div className={classes.receipt_holder}>
        <div className={classes.receipt} id="receipt">
          {receiptStatus === PUBLIC_RECEIPT_STATUS_UNDEFINED && (
            <>
              <div className={classes.no_receipt}>
                <img alt="чек формируется..." src="/images/sp1.svg" />
                <div>чек формируется...</div>
              </div>
            </>
          )}
          {receiptLoading == false && (
            <>
              {(receiptStatus === PUBLIC_RECEIPT_STATUS_PENDING ||
                receiptStatus === PUBLIC_RECEIPT_STATUS_READY) && (
                <>
                  <div
                    className={classes.receipt__header}
                    id="receipt-header"
                    data-filename={
                      "Кассовый чек №" +
                      receipt.id +
                      " от " +
                      receipt.public_create_datetime
                    }
                  >
                    <div>Кассовый чек №{receipt.id}</div>
                    <div>{receipt.public_create_datetime}</div>
                      {receipt.company && (
                        <div>
                            {receipt.company}
                        </div>
                      )}
                      {receipt.inn && (
                        <div>
                            ИНН {receipt.inn}
                        </div>
                      )}
                      {receipt.taxation && (
                          <div>
                              Вид налогообложения: {receipt.taxation}
                          </div>
                      )}

                  </div>
                  <div className={classes.receipt__line}>* * * * *</div>
                  <div className={classes.receipt__body}>
                    <table className={classes.receipt__body_table}>
                      <tbody>
                        <tr>
                          <td
                            className={
                              classes.receipt__body_table_tr_td_first_child
                            }
                          >
                            {receipt.service}
                          </td>
                          <td
                            className={
                              classes.receipt__body_table_tr_td_last_child
                            }
                          >
                            {receipt.amount} руб.
                          </td>
                        </tr>
                        {receipt.nds_amount > 0 && (
                            <tr>
                              <td
                                className={
                                  classes.receipt__body_table_tr_td_small_left
                                }
                              >
                                (в т.ч. НДС {receipt.nds}%)
                              </td>
                              <td
                                className={
                                  classes.receipt__body_table_tr_td_small_right
                                }
                              >
                                {receipt.nds_amount} руб.
                              </td>
                            </tr>
                        )}
                        <tr>
                          <td
                            className={
                              classes.receipt__body_table_tr_td_bold_left
                            }
                          >
                            ИТОГ
                          </td>
                          <td
                            className={
                              classes.receipt__body_table_tr_td_bold_right
                            }
                          >
                            {receipt.amount} руб.
                          </td>
                        </tr>
                        {receipt.nds_amount > 0 && (
                            <tr>
                              <td
                                className={
                                  classes.receipt__body_table_tr_td_small_left
                                }
                              >
                                (в т.ч. НДС {receipt.nds}%)
                              </td>
                              <td
                                className={
                                  classes.receipt__body_table_tr_td_small_right
                                }
                              >
                                {receipt.nds_amount} руб.
                              </td>
                            </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className={classes.receipt__prefooter}>
                    <div className={classes.receipt__title}>ФП</div>
                    <div>ФН: {receipt.fn}</div>
                    <div>ФД: {receipt.fd}</div>
                    <div>ФПД: {receipt.fp}</div>
                    <div>Сайт ФНС:</div>
                    <div>www.nalog.ru</div>
                    <div className={classes.receipt__prefooter__qrcode}>
                      {receiptStatus === PUBLIC_RECEIPT_STATUS_READY && (
                        <div
                          className={classes.receipt__prefooter__qrcode_imgcont}
                        >
                          <img
                            alt="qr-code чека"
                            className={classes.receipt__prefooter__qrcode_img}
                            src={
                              "data:image/png;base64," + receipt.qrcode_base64
                            }
                          />
                        </div>
                      )}
                      {receiptStatus === PUBLIC_RECEIPT_STATUS_PENDING && (
                        <>
                          <div className="no-receipt-2">
                            <img alt="фискализация..." src="/images/sp2.svg" />
                            <div>фискализация...</div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className={classes.receipt__footer}>
                    <></>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>

      <div className={classes.buttons}>
        <button className={classes.button} id="download-pdf">
          Скачать
        </button>
        <button className={classes.button} onClick={() => window.print()}>
          Распечатать
        </button>
      </div>
      <div className={classes.copyright}>
        ©{" "}
        <a className={classes.copyright_a} href="https://uchecker.ru/">
          uChecker
        </a>{" "}
        — решение по ФЗ-54 для автомоек самообслуживания
      </div>
    </div>
  );
};
