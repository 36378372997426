import { call, put, select, takeEvery } from "redux-saga/effects";
import { BaseAction } from "../Common/CommonModel";
import { State } from "../reducers";
import { enqueueSnackbar } from "../Snackbar/SnackbarAction";
import {
  userCreateRequestCompletedAction,
  userCreateRequestFailedAction,
  userDirectoryRequestCompletedAction,
  userDirectoryRequestFailedAction,
  usersRequestCompletedAction,
  usersRequestFailedAction,
  usersRequestStartAction,
  userUpdateRequestCompletedAction,
  userUpdateRequestFailedAction,
} from "./UserAction";
import {
  getUserDirectory,
  getUsersList,
  postUserCreate,
  postUserUpdate,
} from "./UserApis";
import * as types from "./UserTypes";

export function* watchUserRequestStart() {
  yield takeEvery(types.GET_USER_LIST_START, requestUserList);
  yield takeEvery(types.GET_USER_DIRECTORY_START, requestUserDirectory);
  yield takeEvery(types.CREATE_USER_START, requestCreateUser);
  yield takeEvery(types.UPDATE_USER_START, requestUpdateUser);
}

const getFilter = (state: State) => state.user.filter;

function* requestUserList(action: BaseAction) {
  try {
    const prjId = 0;
    const response = yield call(getUsersList, prjId, {
      ...action.payload,
      filter: {
        ...action.payload.filter,
        page: action.payload.filter.page + 1,
      },
    });

    if (response.status != 200) {
      throw { message: "Неизвестная ошибка" };
    }

    yield put(
      usersRequestCompletedAction(response.data["list"], {
        ...response.data["pagination"],
        page: response.data["pagination"].page - 1,
      })
    );
  } catch (e) {
    yield put(usersRequestFailedAction());

    const notification = {
      message: "Не удалось получить список клиентов (" + e.message + ")",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    };

    yield put(enqueueSnackbar(notification));
  }
}

// ***

function* requestUserDirectory(action: BaseAction) {
  try {
    const prjId = 0;
    const response = yield call(getUserDirectory, prjId, action.payload);

    if (response.status != 200) {
      throw { message: "Неизвестная ошибка" };
    }

    yield put(userDirectoryRequestCompletedAction(response.data));
  } catch (e) {
    yield put(userDirectoryRequestFailedAction());

    const notification = {
      message: "Не удалось получить справочник устройств (" + e.message + ")",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    };

    yield put(enqueueSnackbar(notification));
  }
}

// ***

function* requestCreateUser(action: BaseAction) {
  try {
    const prjId = 0;
    const response = yield call(postUserCreate, prjId, action.payload);

    if (response.status != 200) {
      throw { message: "Неизвестная ошибка" };
    }

    yield put(userCreateRequestCompletedAction());
  } catch (e) {
    yield put(userCreateRequestFailedAction());

    const notification = {
      message: "Не удалось создать пользователя",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    };

    yield put(enqueueSnackbar(notification));
  }

  const filter = yield select(getFilter);
  yield put(usersRequestStartAction(filter));
}

function* requestUpdateUser(action: BaseAction) {
  try {
    const prjId = 0;
    const response = yield call(postUserUpdate, prjId, action.payload);

    if (response.status != 200) {
      throw { message: "Неизвестная ошибка" };
    }

    yield put(userUpdateRequestCompletedAction());
  } catch (e) {
    yield put(userUpdateRequestFailedAction());

    const notification = {
      message: "Не удалось сохранить изменения",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    };

    yield put(enqueueSnackbar(notification));
  }

  const filter = yield select(getFilter);
  yield put(usersRequestStartAction(filter));
}
