import { BaseAction, Directory, Pagination } from "../Common/CommonModel";
import { DeviceSetting, DeviceSettingFilter, DeviceSettingUpdateForm, DeviceSettingCreateForm } from "./DeviceSettingModel";
import * as types from "./DeviceSettingTypes";

export const devicesSettingsRequestStartAction = (
  filter: DeviceSettingFilter
): BaseAction => ({
  type: types.GET_DEVICE_SETTING_LIST_START,
  payload: {
    filter,
  },
});

export const devicesSettingsRequestCompletedAction = (
  list: DeviceSetting[],
  pagination: Pagination
): BaseAction => ({
  type: types.GET_DEVICE_SETTING_LIST_COMPLETED,
  payload: {
    items: list,
    pagination: pagination,
  },
});

export const devicesSettingsRequestFailedAction = (): BaseAction => ({
  type: types.GET_DEVICE_SETTING_LIST_FAILED,
  payload: {},
});

// ***

export const deviceSettingDirectoryRequestStartAction = (): BaseAction => ({
  type: types.GET_DEVICE_SETTING_DIRECTORY_START,
  payload: {},
});

export const deviceSettingDirectoryRequestCompletedAction = (
  list: Directory[]
): BaseAction => ({
  type: types.GET_DEVICE_SETTING_DIRECTORY_COMPLETED,
  payload: {
    items: list,
  },
});

export const deviceSettingDirectoryRequestFailedAction = (): BaseAction => ({
  type: types.GET_DEVICE_SETTING_DIRECTORY_FAILED,
  payload: {},
});

// ***

export const deviceSettingBlockStartAction = (id: number): BaseAction => ({
  type: types.BLOCK_DEVICE_SETTING_START,
  payload: {
    id: id,
  },
});

export const deviceSettingBlockCompletedAction = (data): BaseAction => ({
  type: types.BLOCK_DEVICE_SETTING_COMPLETED,
  payload: data,
});

export const deviceSettingBlockFailedAction = (): BaseAction => ({
  type: types.BLOCK_DEVICE_SETTING_FAILED,
  payload: null,
});


export const deviceSettingUpdateRequestStartAction = (
  id: number,
  form: DeviceSettingUpdateForm
): BaseAction => ({
  type: types.UPDATE_DEVICE_SETTING_START,
  payload: {
    id: id,
    form: form,
  },
});

export const deviceSettingUpdateRequestCompletedAction = (): BaseAction => ({
  type: types.UPDATE_DEVICE_SETTING_COMPLETED,
  payload: {},
});

export const deviceSettingUpdateRequestFailedAction = (): BaseAction => ({
  type: types.UPDATE_DEVICE_SETTING_FAILED,
  payload: {},
});

// ***

export const deviceSettingCreateRequestStartAction = (
    form: DeviceSettingCreateForm
): BaseAction => ({
  type: types.CREATE_DEVICE_SETTING_START,
  payload: {
    form: form,
  },
});

export const deviceSettingCreateRequestCompletedAction = (): BaseAction => ({
  type: types.CREATE_DEVICE_SETTING_COMPLETED,
  payload: {},
});

export const deviceSettingCreateRequestFailedAction = (): BaseAction => ({
  type: types.CREATE_DEVICE_SETTING_FAILED,
  payload: {},
});

// ***
