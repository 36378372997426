/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    RECEIPT_STATUS_ERROR,
    RECEIPT_STATUS_NEW,
    RECEIPT_STATUS_PENDING,
    RECEIPT_STATUS_PENDING_SEND, RECEIPT_STATUS_RETURN,
    RECEIPT_STATUS_SCIPPED,
    RECEIPT_STATUS_SUCCESS,
    RECEIPT_STATUS_WARNING
} from '../../store/Receipt/ReceiptConstant';
import { Directory } from '../../store/Common/CommonModel';
import { createStyles, FormControl, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: 0,
      minWidth: 150,
      width: "100%",
    },
  })
);

export const StatusAutoFilter = (props: any) => {
  const classes = useStyles();
  const { value, onChange } = props;
  const [filter, setFilter] = React.useState<string | null>(statuses[0].title);

 
  return (
    <FormControl className={classes.formControl}>
      <Autocomplete
        id="combo-box-demo"
        options={statuses}
        getOptionLabel={(option) => option.title}
        onChange={(event: any, newValue: string | null) => {
          if (newValue==null){
            onChange(0);
          } else {
          setFilter(newValue);
          onChange(newValue['code']);
          }
        }}
        renderInput={(params) => <TextField {...params} label="Статус" margin="normal" />}
      />
    </FormControl>
  );
}

const statuses = [
  { title: "Новый", code: RECEIPT_STATUS_NEW },
  { title: "Взято в работу", code: RECEIPT_STATUS_PENDING },
  { title: "В работе (отправлено)", code: RECEIPT_STATUS_PENDING_SEND },
  { title: "Фискализирован", code: RECEIPT_STATUS_SUCCESS },
  { title: "Ошибка", code: RECEIPT_STATUS_ERROR },
  { title: "Пропущен", code: RECEIPT_STATUS_SCIPPED },
  { title: "На модерации", code: RECEIPT_STATUS_WARNING },
  { title: "Возврат", code: RECEIPT_STATUS_RETURN }
];
