export const GET_DEVICE_LIST_START = "GET_DEVICE_LIST - Start";
export const GET_DEVICE_LIST_COMPLETED = "GET_DEVICE_LIST - Completed";
export const GET_DEVICE_LIST_FAILED = "GET_DEVICE_LIST - Failed";
export const GET_DEVICE_DIRECTORY_START = "GET_DEVICE_DIRECTORY - Start";
export const GET_DEVICE_DIRECTORY_COMPLETED = "GET_DEVICE_DIRECTORY - Completed";
export const GET_DEVICE_DIRECTORY_FAILED = "GET_DEVICE_DIRECTORY - Failed";

export const BLOCK_DEVICE_START = "BLOCK_DEVICE - Start";
export const BLOCK_DEVICE_COMPLETED = "BLOCK_DEVICE - Completed";
export const BLOCK_DEVICE_FAILED = "BLOCK_DEVICE - Failed";
export const UNBLOCK_DEVICE_START = "UNBLOCK_DEVICE - Start";
export const UNBLOCK_DEVICE_COMPLETED = "UNBLOCK_DEVICE - Completed";
export const UNBLOCK_DEVICE_FAILED = "UNBLOCK_DEVICE - Failed";

export const UPDATE_DEVICE_START = "UPDATE_DEVICE - Start";
export const UPDATE_DEVICE_COMPLETED = "UPDATE_DEVICE - Completed";
export const UPDATE_DEVICE_FAILED = "UPDATE_DEVICE - Failed";
