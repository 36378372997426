import { Box } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import Moment from "react-moment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    date: {
      whiteSpace: "nowrap",
      marginRight: 3,
    },
    time: {
      fontSize: 13,
      marginLeft: 3,
      whiteSpace: "nowrap",
    },
  })
);

const DateTime = (props: any) => {
  const classes = useStyles();
  const { dateFormat, timeFormat, timeZone, dateTime } = props;

  if (dateTime == null) {
    return <>{"-"}</>;
  }

  return (
    <>
      <Box component="span" className={classes.date}>
        <Moment format={dateFormat} tz={timeZone}>
          {dateTime}
        </Moment>
      </Box>
      <Box component="span" className={classes.time}>
        <Moment format={timeFormat} tz={timeZone}>
          {dateTime}
        </Moment>
      </Box>
    </>
  );
};

export default DateTime;
