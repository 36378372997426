import { connect } from "react-redux";
import { signOutAction } from "../../store/Auth/AuthAction";
import { toggleNavAction } from "../../store/Common/CommonActions";
import { State } from "../../store/reducers";
import { HeaderComponent } from "./header.component";

const mapStateToProps = (state: State) => ({
  nav: state.nav,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: any) => ({
  onSignOutAction: () => dispatch(signOutAction()),
  onToggleNav: (isOpen: boolean) => dispatch(toggleNavAction(isOpen)),
});

export const HeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderComponent);
