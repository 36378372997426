import { BaseAction } from "../store/Common/CommonModel";
import * as types from '../store/Common/CommonTypes';

export type NavState = {
    isOpen: boolean;
    pagePath: string;
};

export const navReducer = (
    state: NavState = {
        isOpen: false,
        pagePath: window.location.pathname,
    },
    action: BaseAction
) => {
    switch (action.type) {
        case types.TOGGLE_NAV:
            return {
                ...state,
                isOpen: action.payload.isOpen,
            };

        case types.CHANGE_PATH:
            return {
                ...state,
                pagePath: action.payload.path,
            };
    }

    return state;
};
