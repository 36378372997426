import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { DeviceSettingCreateForm } from "../../store/DeviceSetting/DeviceSettingModel";
import { Directory } from "../../store/Common/CommonModel";
import { DeviceFilter } from "../../components/filters/device-filter";
import { DeviceSettingFilterComp } from "../../components/filters/device-setting-filter";

interface Props {
  isAdmin: boolean;
  deviceSettingCreating: boolean;
  onRequestDeviceSettingCreate: (form: DeviceSettingCreateForm) => void;
  deviceDirectory: Directory[];
  onRequestDeviceDirectory: () => void;
  deviceSettingDirectory: Directory[];
  onRequestDeviceSettingDirectory: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      marginLeft: 0,
      marginRight: 0,
      width: "100%",
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
  })
);

export const FormComponent = (props: any) => {
  const classes = useStyles();
  const {
    isAdmin,
    handleClose,
    deviceSettingCreating,
    onRequestDeviceSettingCreate,
    deviceDirectory,
    onRequestDeviceDirectory,
    deviceSettingDirectory,
    onRequestDeviceSettingDirectory,
  } = props;

  const createForm = {
    device_id: 0,
    key: "",
    value: ""
  };

  const [fields, setFields] = React.useState<DeviceSettingCreateForm>(createForm);

  const handleChangeField =
    (prop: keyof DeviceSettingCreateForm) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFields({ ...fields, [prop]: event.target.value });
    };

    const handleChangeDevice = (value: any) => {
        setFields({ ...fields, device_id: value });
    };

    const handleChangeDeviceSetting = (value: any) => {
        setFields({ ...fields, key: value });
    };

  // ***

  const handleStartCreate = () => {
    onRequestDeviceSettingCreate(fields);
    handleClose();
  };
    useEffect(() => {
        onRequestDeviceDirectory();
        onRequestDeviceSettingDirectory();
    }, []);

  return (
    <>
      <DialogTitle>Создание настройки</DialogTitle>
      <DialogContent dividers>
          <Grid container spacing={3}>
              <Grid item xs={12}>
                  <FormControl className={classes.formControl}>
                      <DeviceFilter
                          value={fields.device_id}
                          variants={deviceDirectory}
                          onChange={handleChangeDevice}
                      ></DeviceFilter>
                  </FormControl>
              </Grid>
          </Grid>
          <Grid container spacing={3}>
              <Grid item xs={12}>
                  <FormControl className={classes.formControl}>
                      <DeviceSettingFilterComp
                          value={fields.key}
                          variants={deviceSettingDirectory}
                          onChange={handleChangeDeviceSetting}
                      ></DeviceSettingFilterComp>
                  </FormControl>
              </Grid>
          </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl className={classes.formControl}>
            <TextField
                value={fields.value}
                label="Значение"
                onChange={handleChangeField("value")}
            />
          </FormControl>
        </Grid>
      </DialogContent>
      <DialogActions>

            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleStartCreate}
              variant="contained"
              color="primary"
            >
              Сохранить
            </Button>

      </DialogActions>
    </>
  );
};
