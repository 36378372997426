import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Hidden,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import {
  createStyles,
  lighten,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import ListIcon from "@material-ui/icons/List";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { default as React, useEffect } from "react";
import { AmountFilter } from "../../components/filters/amount-filter";
import { DateFilter } from "../../components/filters/date-filter";
import { NumberFieldFilter } from "../../components/filters/number-field-filter";
import { StatusAutoFilter } from "../../components/filters/status-auto-filter";

import DateTime from "../../components/formatters/date-time";
import { ReceiptLogs } from "../../components/receipt-logs";
import ReceiptStatus from "../../components/receipt-status";
import TableSortHeader from "../../components/table-sort-header";
import useFilters from "../../hooks/useFilters";
import { Pagination } from "../../store/Common/CommonModel";
import * as statuses from "../../store/Receipt/ReceiptConstant";
import { Receipt, ReceiptFilter } from "../../store/Receipt/ReceiptModel";
import { Settings } from "../../store/Settings/SettingsModel";

interface Props {
  receiptsLoading: boolean;
  receipts: Receipt[];
  settings: Settings;
  pagination: Pagination;
  filter: ReceiptFilter;
  onRequestReceiptList: (filter: ReceiptFilter) => void;
  onRequestReceiptLogs: (id: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      // minWidth: 700,
    },
    progress: {
      textAlign: "center",
    },
    buttonView: {
      margin: theme.spacing(1),
      marginRight: 0,
    },
    headCell: {
      paddingTop: 5,
      verticalAlign: "top",
      fontWeight: "bold",
    },
    filterCell: {
      paddingTop: 6,
      verticalAlign: "bottom",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    menuItem: {
      color: "#000",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    statusDefault: {},
    statusNew: {
      //   backgroundColor: lighten(theme.palette.default.light, 0.75),
    },
    statusPending: {
      backgroundColor: lighten(theme.palette.warning.light, 0.75),
    },
    statusError: {
      backgroundColor: lighten(theme.palette.error.light, 0.75),
    },
    statusSuccess: {
      backgroundColor: lighten(theme.palette.success.light, 0.75),
    },
    statusScipped: {
      //   backgroundColor: lighten(theme.palette.success.light, 0.75),
    },
    buttonCreate: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    root: {
      width: '100%',
    },
    container: {
      maxHeight: 700,
    },
  })
);

export const PageComponent: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const {
    receiptsLoading,
    receipts,
    filter,
    pagination,
    settings,
    onRequestReceiptList,
    onRequestReceiptLogs,
  } = props;

  useEffect(() => {
    onRequestReceiptList(filter);
  }, []);

  // ***

  const [newFilter, handleChangeOrder, handleChangeValue, handleChangeNumberField] = useFilters(
    filter,
    onRequestReceiptList
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLButtonElement>(
    null
  );
  const [openActions, setOpenActions] = React.useState<null | Number>(null);

  const handleActionClick = (
    id: Number,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
    setOpenActions(id);
  };

  const handleActionClose = () => {
    setOpenActions(null);
    setAnchorEl(null);
  };

  // ***

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    console.log("Фильтр: ", filter);
    handleChangeValue({ page: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    console.log("Фильтр: ", filter);
    handleChangeValue({ page: 0, per_page: parseInt(event.target.value, 10) });
  };

  const handleChangeCreateDate = (startTime, stopTime: string) => {
    handleChangeValue({
      page: 0,
      min_create_date: startTime,
      max_create_date: stopTime,
    });
  };

  // const handleChangeOrder =
  //   (property: string) => (event: React.MouseEvent<unknown>) => {
  //     if (filter.order_by !== property) {
  //       filter.order = "asc";
  //       filter.order_by = property;
  //     } else {
  //       filter.order = filter.order === "asc" ? "desc" : "asc";
  //     }

  //     onRequestReceiptList(filter);
  //   };

  // Просмотр чека

  const [openDetails, setOpenDetails] = React.useState(false);
  const [detailed, setDetailed] = React.useState<Receipt>();

  const handleOpenDetails = (item: Receipt) => () => {
    setDetailed(item);
    setOpenDetails(true);
  };

  const handleCloseDetails = () => {
    setOpenDetails(false);
  };

  //

  const [openLogs, setOpenLogs] = React.useState(false);
  const [logs, setLogs] = React.useState<Receipt>();

  const handleOpenLogs = (item: Receipt) => () => {
    setLogs(item);
    setOpenLogs(true);
    onRequestReceiptLogs(item.id);
  };

  const handleCloseLog = () => {
    setOpenLogs(false);
  };

  // ***

  const getRowStyle = (item: Receipt) => {
    switch (item.status) {
      case statuses.RECEIPT_STATUS_NEW:
        return classes.statusNew;

      case statuses.RECEIPT_STATUS_PENDING:
        return classes.statusPending;

      case statuses.RECEIPT_STATUS_PENDING_SEND:
        return classes.statusPending;

      case statuses.RECEIPT_STATUS_SUCCESS:
        return classes.statusSuccess;

      case statuses.RECEIPT_STATUS_ERROR:
        return classes.statusError;

      case statuses.RECEIPT_STATUS_SCIPPED:
        return classes.statusScipped;

      default:
        return classes.statusDefault;
    }
  };

  return (
    <>
      <h1>Чеки</h1>

      {/* <Paper className={classes.root}> */}
     {/* <TableContainer className={classes.container}> */}
     <TableContainer>
        {/* <Table stickyHeader aria-label="sticky table" className={classes.table} size="small"> */}
      <Table className={classes.table} size="small"> 
          <TableHead>
            <TableRow>
              <TableCell align="center" className={classes.filterCell} width = "5%">
              </TableCell>
              <TableCell align="center" className={classes.filterCell} width = "12%">
                <NumberFieldFilter
                  value={filter.controller}
                  onChange={handleChangeNumberField}
                  idFilter={"controller"}
                  filter={filter}
                />
              </TableCell>
              <TableCell align="left" className={classes.filterCell} width = "12%">
                <NumberFieldFilter
                  value={filter.check_id}
                  onChange={handleChangeNumberField}
                  idFilter={"check_id"}
                  filter={filter}
                />
              </TableCell>
              <TableCell align="center" className={classes.filterCell} width = "5%">
              </TableCell>
              <TableCell align="center" className={classes.filterCell} width = "15%">
               <DateFilter
                  startTime={filter.min_create_date}
                  stopTime={filter.max_create_date}
                  onChange={handleChangeCreateDate}
                />
              </TableCell>
              <TableCell align="center" className={classes.filterCell} width = "12%">
              </TableCell>
              <TableCell align="center" className={classes.filterCell} width = "10%">
                <AmountFilter
                  amount_to={filter.amount_to}
                  amount_from={filter.amount_from}
                  onChange={handleChangeValue}
                />
              </TableCell>
              <TableCell align="center" className={classes.filterCell} width = "5%">
              </TableCell>
              <TableCell align="center" className={classes.filterCell} >
                <StatusAutoFilter
                  value={filter.status}
                  onChange={(value: any) =>
                    handleChangeValue({ status: value })
                  }
                />
              </TableCell>
              <TableCell align="right" className={classes.headCell} width = "10%">
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" className={classes.headCell} width = "5%">
                <TableSortHeader
                  id="id"
                  title="ID"
                  order={filter.order}
                  orderBy={filter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="center" className={classes.headCell} width = "12%">
              <TableSortHeader
                  id="controller"
                  title="Контроллер"
                  order={filter.order}
                  orderBy={filter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="left" className={classes.headCell} width = "10%">
              <TableSortHeader
                  id="check_id"
                  title="Номер чека"
                  order={filter.order}
                  orderBy={filter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="center" className={classes.headCell} width = "5%">
                <TableSortHeader
                  id="service"
                  title="Услуга"
                  order={filter.order}
                  orderBy={filter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
            <TableCell align="center" className={classes.headCell} width = "10%">
              <TableSortHeader
                  id="create_datetime"
                  title="Дата создания"
                  order={filter.order}
                  orderBy={filter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="center" className={classes.headCell} width = "10%">
                <TableSortHeader
                  id="fiscal_datetime"
                  title="Дата фискализации"
                  order={filter.order}
                  orderBy={filter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="center" className={classes.headCell} width = "10%">
              <TableSortHeader
                  id="amount"
                  title="Сумма"
                  order={filter.order}
                  orderBy={filter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="center" className={classes.headCell} >
                <TableSortHeader
                    id="cash_type"
                    title="Тип платежа"
                    order={filter.order}
                    orderBy={filter.order_by}
                    onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="center" className={classes.headCell} >
              <TableSortHeader
                  id="status"
                  title="Статус"
                  order={filter.order}
                  orderBy={filter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="right" className={classes.headCell} width = "12%">
                Действия
              </TableCell>
            </TableRow>

          </TableHead>

          <TableBody>
            {receipts &&
              0 < receipts.length &&
              receipts.map((item) => (
                <TableRow className={getRowStyle(item)} hover key={item.id}>
                  <TableCell align="center" width = "5%">{item.id}</TableCell>
                  <TableCell align="center" width = "12%">{item.controller}</TableCell>
                  <TableCell align="center" width = "12%">{item.check_id}</TableCell>
                  <TableCell align="center" width = "5%">{item.service}</TableCell>
                  <TableCell align="center" width = "14%">
                    <DateTime
                      dateFormat={settings.dateFormat}
                      timeFormat={settings.timeFormat}
                      timeZone={settings.timeZone}
                      dateTime={item.create_datetime}
                    />
                  </TableCell>
                  <TableCell align="center" width = "12%">
                    <DateTime
                      dateFormat={settings.dateFormat}
                      timeFormat={settings.timeFormat}
                      timeZone={settings.timeZone}
                      dateTime={item.fiscal_datetime}
                    />
                  </TableCell>
                  <TableCell align="center" width = "12%">{item.amount}</TableCell>
                  <TableCell align="center" width = "12%">{item.cash_type_title}</TableCell>
                  <TableCell align="center">
                    <ReceiptStatus value={item.status} />
                  </TableCell>
                  <TableCell align="right"  width = "10%">
                    <Hidden only={["xs", "sm", "md"]}>
                      <IconButton
                        color="primary"
                        onClick={handleOpenDetails(item)}
                      >
                        <Tooltip title="Детали">
                          <VisibilityIcon />
                        </Tooltip>
                      </IconButton>

                      <IconButton
                        color="primary"
                        onClick={handleOpenLogs(item)}
                      >
                        <Tooltip title="Лог">
                          <ListIcon />
                        </Tooltip>
                      </IconButton>

                      <IconButton
                        color="primary"
                        component={Link}
                        href={"/c/" + item.controller + "/" + item.check_id}
                        target="_blank"
                        underline="none"
                      >
                        <Tooltip title="Чек">
                          <OpenInNewIcon />
                        </Tooltip>
                      </IconButton>
                    </Hidden>

                    <Hidden only={["lg", "xl"]}>
                      <Popper
                        id={"actions-" + item.id}
                        open={openActions === item.id}
                        anchorEl={anchorEl}
                        placement="bottom"
                      >
                        <Menu
                          id={"actions-receipt-" + item.id}
                          anchorEl={anchorEl}
                          keepMounted
                          open={true}
                          onClick={handleActionClose}
                          onClose={handleActionClose}
                        >
                          <MenuItem
                            color="primary"
                            onClick={handleOpenDetails(item)}
                          >
                            Детали
                          </MenuItem>

                          <MenuItem
                            color="primary"
                            onClick={handleOpenLogs(item)}
                          >
                            Лог
                          </MenuItem>

                          <MenuItem
                            color="primary"
                            component={Link}
                            href={"/c/" + item.controller + "/" + item.check_id}
                            target="_blank"
                            underline="none"
                            className={classes.menuItem}
                          >
                            Чек
                          </MenuItem>
                        </Menu>
                      </Popper>

                      <IconButton
                        id={"show-actions-" + item.id}
                        aria-haspopup="true"
                        onClick={(e) => handleActionClick(item.id, e)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Hidden>
                  </TableCell>
                </TableRow>
              ))}

            {(!receipts || receipts.length === 0) && (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  Записей нет
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {(1 < pagination.page_count || 10 < pagination.per_page) && (
        <TablePagination
          component="div"
          count={pagination.total_count}
          page={pagination.page}
          onChangePage={handleChangePage}
          rowsPerPage={pagination.per_page}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="Per page:"
        />
      )}
{/* </Paper> */}
      <Dialog
        scroll="paper"
        onClose={handleCloseDetails}
        aria-labelledby="customized-dialog-title"
        open={openDetails}
        maxWidth="md"
        fullWidth={true}
      >
        {detailed && (
          <>
            <DialogTitle id="customized-dialog-title">
              Чек #<b>{detailed.id}</b>
            </DialogTitle>
            <DialogContent dividers>
              <Table className={classes.table} size="small">
                <TableBody>
                  <TableRow hover>
                    <TableCell align="left">ID</TableCell>
                    <TableCell align="center">{detailed.id}</TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell align="left">Тип платежа</TableCell>
                    <TableCell align="center">{detailed.cash_type_title}</TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell align="left">ID Типа платежа</TableCell>
                    <TableCell align="center">{detailed.cash_type}</TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell align="left">Сумма</TableCell>
                    <TableCell align="center">{detailed.amount}</TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell align="left">Сумма НДС</TableCell>
                    <TableCell align="center">{detailed.nds_amount}</TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell align="left">Статус</TableCell>
                    <TableCell align="center">
                      {detailed.status}
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell align="left">Дата создания</TableCell>
                    <TableCell align="center">
                      <DateTime
                        dateFormat={settings.dateFormat}
                        timeFormat={settings.timeFormat}
                        timeZone={settings.timeZone}
                        dateTime={detailed.create_datetime}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell align="left">Дата фискализации</TableCell>
                    <TableCell align="center">
                      <DateTime
                        dateFormat={settings.dateFormat}
                        timeFormat={settings.timeFormat}
                        timeZone={settings.timeZone}
                        dateTime={detailed.fiscal_datetime}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell align="left">Дата события в Аксиот</TableCell>
                    <TableCell align="center">
                    <DateTime
                        dateFormat={settings.dateFormat}
                        timeFormat={settings.timeFormat}
                        timeZone={settings.timeZone}
                        dateTime={detailed.axiot_datetime}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell align="left">CONTROLLER</TableCell>
                    <TableCell align="center">
                      {detailed.controller}
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell align="left">CHECK_ID</TableCell>
                    <TableCell align="center">
                      {detailed.check_id}
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell align="left">EXT_ID</TableCell>
                    <TableCell align="center">
                      {detailed.ext_id}
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell align="left">ФН</TableCell>
                    <TableCell align="center">
                      {detailed.fn}
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell align="left">ФП</TableCell>
                    <TableCell align="center">
                      {detailed.fp}
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell align="left">ФД</TableCell>
                    <TableCell align="center">
                      {detailed.fd}
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell align="left">Ссылка</TableCell>
                    <TableCell align="center">
                      {detailed.link}
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell align="left">Проект</TableCell>
                    <TableCell align="center">
                      {detailed.project}
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell align="left">Услуга</TableCell>
                    <TableCell align="center">
                      {detailed.service}
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell align="left">Компания</TableCell>
                    <TableCell align="center">
                      {detailed.company}
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell align="left">ИНН</TableCell>
                    <TableCell align="center">
                      {detailed.inn}
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell align="left">Налогооблажение</TableCell>
                    <TableCell align="center">
                      {detailed.taxation}
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell align="left">НДС</TableCell>
                    <TableCell align="center">
                      {detailed.nds}
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell align="left">Отправлено в Аксиот</TableCell>
                    <TableCell align="center">
                      {detailed.axiot_write ? 'да' : 'нет'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </DialogContent>
          </>
        )}
        <DialogActions>
          <Button onClick={handleCloseDetails} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        scroll="paper"
        onClose={handleCloseLog}
        aria-labelledby="customized-dialog-title"
        open={openLogs}
        maxWidth="md"
        fullWidth={true}
      >
        {logs && (
          <>
            <DialogTitle id="customized-dialog-title">
              Чек #<b>{logs.id}</b>
            </DialogTitle>
            <DialogContent dividers>
              <ReceiptLogs></ReceiptLogs>
            </DialogContent>
          </>
        )}
        <DialogActions>
          <Button onClick={handleCloseLog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop className={classes.backdrop} open={receiptsLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
