import { BaseAction, Pagination } from "../Common/CommonModel";
import { Receipt, ReceiptFilter, ReceiptLog } from "./ReceiptModel";
import * as types from "./ReceiptTypes";

// ***

export const receiptsRequestStartAction = (
  filter: ReceiptFilter
): BaseAction => ({
  type: types.GET_RECEIPT_LIST_START,
  payload: {
    filter: filter,
  },
});

export const receiptsRequestCompletedAction = (
  list: Receipt[],
  pagination: Pagination
): BaseAction => ({
  type: types.GET_RECEIPT_LIST_COMPLETED,
  payload: {
    items: list,
    pagination: pagination,
  },
});

export const receiptsRequestFailedAction = (): BaseAction => ({
  type: types.GET_RECEIPT_LIST_FAILED,
  payload: {},
});

// ***

export const publicReceiptRequestStartAction = (
  deviceID: number,
  checkID: number
): BaseAction => ({
  type: types.GET_PUBLIC_RECEIPT_ITEM_START,
  payload: {
    deviceID: deviceID,
    checkID: checkID,
  },
});

export const publicReceiptRequestCompletedAction = (
  item: Receipt,
  status: number
): BaseAction => ({
  type: types.GET_PUBLIC_RECEIPT_ITEM_COMPLETED,
  payload: {
    item: item,
    status: status,
  },
});

export const publicReceiptRequestFailedAction = (): BaseAction => ({
  type: types.GET_PUBLIC_RECEIPT_ITEM_FAILED,
  payload: {},
});

// ***

export const receiptLogsRequestStartAction = (
  receiptId: number
): BaseAction => ({
  type: types.GET_RECEIPT_LOG_START,
  payload: {
    receiptId: receiptId,
  },
});

export const receiptLogsRequestCompletedAction = (
  list: ReceiptLog[]
): BaseAction => ({
  type: types.GET_RECEIPT_LOG_COMPLETED,
  payload: {
    items: list,
  },
});

export const receiptLogsRequestFailedAction = (): BaseAction => ({
  type: types.GET_RECEIPT_LOG_FAILED,
  payload: {},
});
