import { BaseAction, Directory, Pagination } from "../Common/CommonModel";
import { Account, AccountFilter } from "./AccountModel";
import * as types from "./AccountTypes";

export type AccountState = {
  list: {
    loading: boolean;
    items: Account[];
    pagination: Pagination;
  };
  directory: {
    loading: boolean;
    items: Directory[];
  };
  nds_directory: {
    loading: boolean;
    items: Directory[];
  };
  taxation_directory: {
    loading: boolean;
    items: Directory[];
  };
  provider_directory: {
    loading: boolean;
    items: Directory[];
  };
  creating: boolean;
  filter: AccountFilter;
};

const defaultAccountPagination = {
  page: 0,
  page_count: 0,
  per_page: 10,
  total_count: 0,
};

export const defaultAccountState: AccountState = {
  list: {
    loading: false,
    items: [],
    pagination: defaultAccountPagination,
  },
  directory: {
    loading: false,
    items: [],
  },
  nds_directory: {
    loading: false,
    items: [],
  },
  provider_directory: {
    loading: false,
    items: [],
  },
  taxation_directory: {
    loading: false,
    items: [],
  },
  creating: false,
  filter: {
    page: 0,
    per_page: defaultAccountPagination.per_page,
    order_by: "id",
    order: "asc",
    inn: "",
    company: "",
  },
};

export const accountReducer = (
  state: AccountState = defaultAccountState,
  action: BaseAction
) => {
  switch (action.type) {
    case types.GET_ACCOUNT_LIST_START:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };

    case types.GET_ACCOUNT_LIST_COMPLETED:
      return {
        ...state,
        list: {
          loading: false,
          items: action.payload.items,
          pagination: action.payload.pagination,
        },
      };

    case types.GET_ACCOUNT_LIST_FAILED:
      return {
        ...state,
        list: {
          loading: false,
          items: [],
          pagination: defaultAccountPagination,
        },
      };

    // ***

    case types.GET_ACCOUNT_DIRECTORY_START:
      return {
        ...state,
        directory: {
          items: [],
          loading: true,
        },
      };

    case types.GET_ACCOUNT_DIRECTORY_COMPLETED:
      return {
        ...state,
        directory: {
          loading: false,
          items: action.payload.items,
        },
      };

    case types.GET_ACCOUNT_DIRECTORY_FAILED:
      return {
        ...state,
        directory: {
          loading: false,
          items: [],
        },
      };

    // ***

    case types.CREATE_ACCOUNT_START:
      return {
        ...state,
        creating: true,
      };

    case types.CREATE_ACCOUNT_COMPLETED:
      return {
        ...state,
        creating: false,
      };

    case types.CREATE_ACCOUNT_FAILED:
      return {
        ...state,
        creating: false,
      };


    case types.GET_NDS_DIRECTORY_START:
      return {
        ...state,
        nds_directory: {
          items: [],
          loading: true,
        },
      };

    case types.GET_NDS_DIRECTORY_COMPLETED:
      return {
        ...state,
        nds_directory: {
          loading: false,
          items: action.payload.items,
        },
      };

    case types.GET_NDS_DIRECTORY_FAILED:
      return {
        ...state,
        nds_directory: {
          loading: false,
          items: [],
        },
      };

    case types.GET_TAXATION_DIRECTORY_START:
      return {
        ...state,
        taxation_directory: {
          items: [],
          loading: true,
        },
      };

    case types.GET_TAXATION_DIRECTORY_COMPLETED:
      return {
        ...state,
        taxation_directory: {
          loading: false,
          items: action.payload.items,
        },
      };

    case types.GET_TAXATION_DIRECTORY_FAILED:
      return {
        ...state,
        taxation_directory: {
          loading: false,
          items: [],
        },
      };

    case types.GET_PROVIDER_DIRECTORY_START:
      return {
        ...state,
        provider_directory: {
          items: [],
          loading: true,
        },
      };

    case types.GET_PROVIDER_DIRECTORY_COMPLETED:
      return {
        ...state,
        provider_directory: {
          loading: false,
          items: action.payload.items,
        },
      };

    case types.GET_PROVIDER_DIRECTORY_FAILED:
      return {
        ...state,
        provider_directory: {
          loading: false,
          items: [],
        },
      };
  }

  return state;
};
