import { call, put, takeEvery } from "redux-saga/effects";
import { BaseAction } from "../Common/CommonModel";
import { enqueueSnackbar } from "../Snackbar/SnackbarAction";
import {
  publicReceiptRequestCompletedAction,
  publicReceiptRequestFailedAction,
  receiptLogsRequestCompletedAction,
  receiptLogsRequestFailedAction,
  receiptsRequestCompletedAction,
  receiptsRequestFailedAction,
} from "./ReceiptAction";
import {
  getPublicReceiptItem,
  getReceiptLogs,
  getReceiptsList,
} from "./ReceiptApis";
import {
  PUBLIC_RECEIPT_STATUS_PENDING,
  PUBLIC_RECEIPT_STATUS_READY,
  PUBLIC_RECEIPT_STATUS_UNDEFINED,
  RECEIPT_STATUS_SUCCESS,
} from "./ReceiptConstant";
import * as types from "./ReceiptTypes";

export function* watchReceiptRequestStart() {
  yield takeEvery(types.GET_RECEIPT_LIST_START, requestReceiptList);
  yield takeEvery(types.GET_RECEIPT_LOG_START, requestReceiptLogs);
  yield takeEvery(
    types.GET_PUBLIC_RECEIPT_ITEM_START,
    requestPublicReceiptItem
  );
}

// ***

function* requestReceiptList(action: BaseAction) {
  try {
    const prjId = 0;
    const response = yield call(getReceiptsList, prjId, {
      ...action.payload,
      filter: {
        ...action.payload.filter,
        page: action.payload.filter.page + 1,
      },
    });

    if (response.status != 200) {
      throw { message: "Неизвестная ошибка" };
    }

    yield put(
      receiptsRequestCompletedAction(response.data["list"], {
        ...response.data["pagination"],
        page: response.data["pagination"].page - 1,
      })
    );
  } catch (e) {
    yield put(receiptsRequestFailedAction());

    const notification = {
      message: "Не удалось получить список чеков (" + e.message + ")",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    };

    yield put(enqueueSnackbar(notification));
  }
}

// ***

function* requestPublicReceiptItem(action: BaseAction) {
  try {
    const prjId = 0;
    const response = yield call(
      getPublicReceiptItem,
      prjId,
      action.payload.deviceID,
      action.payload.checkID
    );

    if (response.status != 200) {
      throw { message: "Неизвестная ошибка" };
    }

    // ***

    let status = PUBLIC_RECEIPT_STATUS_UNDEFINED;
    if (
      response.data &&
      0 < Object.keys(response.data).length &&
      response.data.constructor === Object
    ) {
      status = PUBLIC_RECEIPT_STATUS_PENDING;
      document.title =
        "Кассовый чек №" +
        response.data.id +
        " от " +
        response.data.public_create_datetime +
        (response.data.company && response.data.inn
          ? " — " + response.data.company + " (ИНН " + response.data.inn + ")"
          : "");
    }
    if (
      response.data &&
      response.data.status &&
      response.data.status == RECEIPT_STATUS_SUCCESS
    ) {
      status = PUBLIC_RECEIPT_STATUS_READY;
    }

    yield put(publicReceiptRequestCompletedAction(response.data, status));
  } catch (e) {
    yield put(publicReceiptRequestFailedAction());

    const notification = {
      message: "Не удалось получить чек",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    };

    yield put(enqueueSnackbar(notification));
  }
}

// ***

function* requestReceiptLogs(action: BaseAction) {
  try {
    const prjId = 0;
    const response = yield call(
      getReceiptLogs,
      prjId,
      action.payload.receiptId
    );

    if (response.status != 200) {
      throw { message: "Неизвестная ошибка" };
    }

    yield put(receiptLogsRequestCompletedAction(response.data["list"]));
  } catch (e) {
    yield put(receiptLogsRequestFailedAction());

    const notification = {
      message: "Не удалось получить список логов (" + e.message + ")",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    };

    yield put(enqueueSnackbar(notification));
  }
}
