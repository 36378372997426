import { FormControl, TextField } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import { Directory } from "../../store/Common/CommonModel";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: 0,
      minWidth: 115,
      width: "100%",
    },
  })
);

export const DeviceSettingFilterComp = (props: any) => {
  const classes = useStyles();
  const { value, variants, onChange } = props;

  const [filter, setFilter] = React.useState<Directory>(null);

  if (value && filter == null) {
    const d = variants.find(function (element: Directory) {
      return element.id == value;
    });

    if (d) {
      setFilter(d);
    }
  }

  const handleFilterChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    deviceSetting: Directory
  ) => {
    setFilter(deviceSetting);

    if (deviceSetting) {
      onChange(deviceSetting.id);
    } else {
      onChange(-1);
    }
  };

  return (
    <FormControl className={classes.formControl}>
      <Autocomplete
        id="device-setting-box"
        options={variants}
        getOptionLabel={(option: Directory) =>
          "#" + option.id + " - " + option.title
        }
        getOptionSelected={(option: Directory, value: Directory) => {
          return option.id === value.id;
        }}
        value={filter}
        onChange={handleFilterChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Тип настройки"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </FormControl>
  );
};
