import agentInstance from "../../api/agent";
import { Device } from "../Device/DeviceModel";

export const apiSignIn = (params: {}): Promise<Device[]> => {
    const token = Buffer.from(params["email"]+":"+params["password"], 'utf8').toString('base64');
        
      return new Promise<Device[]>((resolve) => {
        resolve(
            agentInstance(0).post(`auth`, {}, {
                headers: {
                  'Authorization': `Basic ${token}` 
                }
              })
        );
    });
};
