import { combineReducers } from "redux";
import { navReducer, NavState } from "../reducers/nav.reducer";
import {
    accountReducer,
    AccountState
} from "./Account/AccountReducer";
import { authReducer, AuthState } from "./Auth/AuthReducer";
import { deviceReducer, DeviceState } from "./Device/DeviceReducer";
import { deviceSettingReducer, DeviceSettingState } from "./DeviceSetting/DeviceSettingReducer";
import {
    receiptReducer,
    ReceiptState
} from "./Receipt/ReceiptReducer";
import { settingsReducer, SettingsState } from "./Settings/SettingsReducer";
import {
    snackbarReducer,
    SnackbarState
} from "./Snackbar/SnackbarReducer";
import {
    userReducer,
    UserState
} from "./User/UserReducer";

export interface State {
  auth: AuthState;
  nav: NavState;
  device: DeviceState;
  deviceSetting: DeviceSettingState;
  settings: SettingsState;
  snackbar: SnackbarState;
  user: UserState;
  receipt: ReceiptState;
  account: AccountState;
}

export const rootReducer = combineReducers<State>({
  auth: authReducer,
  nav: navReducer,
  device: deviceReducer,
  deviceSetting: deviceSettingReducer,
  settings: settingsReducer,
  snackbar: snackbarReducer,
  user: userReducer,
  receipt: receiptReducer,
  account: accountReducer,
});
