import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import { Directory } from "../../store/Common/CommonModel";

export const AccountFilter = (props: any) => {
  const { value, variants, userValue, users, onChange } = props;

  const [filter, setFilter] = React.useState<Directory>(null);

  if (value && filter == null) {
    const d = variants.find(function (element: Directory) {
      return element.id == value;
    });

    if (d) {
      setFilter(d);
    }
  }

  const handleFilterChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    device: Directory
  ) => {
    setFilter(device);

    if (device) {
      onChange(device.id);
    } else {
      onChange("");
    }
  };

  const userTitle = (id: any) => {
    const d = users.find(function (element: Directory) {
      return element.id == id;
    });

    if (d) {
      return "#" + id + " - " + d.title;
    }

    return "#" + id;
  };

  return (
    <Autocomplete
      id="device-box"
      options={variants}
      getOptionLabel={(option: Directory) =>
        "#" + option.id + " - " + option.title
      }
      getOptionSelected={(option: Directory, value: Directory) => {
        return option.id === value.id;
      }}
      //   filterOptions={(options: Directory[], state: object) => {
      //     return options.filter((item) => {
      //       return item.group == userValue;
      //     });
      //   }}
      value={filter}
      onChange={handleFilterChange}
      groupBy={(option) => userTitle(option.group)}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Реквизиты"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};


export const ProviderFilter = (props: any) => {
  const { value, variants, userValue, users, onChange } = props;

  const [filter, setFilter] = React.useState<Directory>(null);

  if (value && filter == null) {
    const d = variants.find(function (element: Directory) {
      return element.id == value;
    });

    if (d) {
      setFilter(d);
    }
  }

  const handleFilterChange = (
      event: React.MouseEvent<HTMLButtonElement> | null,
      device: Directory
  ) => {
    setFilter(device);

    if (device) {
      onChange(device.id);
    } else {
      onChange("");
    }
  };

  return (
      <Autocomplete
          id="provider-box"
          options={variants}
          getOptionLabel={(option: Directory) =>
              "#" + option.id + " - " + option.title
          }
          getOptionSelected={(option: Directory, value: Directory) => {
            return option.id === value.id;
          }}

          value={filter}
          onChange={handleFilterChange}

          renderInput={(params) => (
              <TextField
                  {...params}
                  label="Провайдер"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
                    ),
                  }}
              />
          )}
      />
  );
};


export const TaxationFilter = (props: any) => {
    const { value, variants, userValue, users, onChange } = props;

    const [filter, setFilter] = React.useState<Directory>(null);

    if (value && filter == null) {
        const d = variants.find(function (element: Directory) {
            return element.id == value;
        });

        if (d) {
            setFilter(d);
        }
    }

    const handleFilterChange = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        device: Directory
    ) => {
        setFilter(device);

        if (device) {
            onChange(device.id);
        } else {
            onChange("");
        }
    };

    return (
        <Autocomplete
            id="taxation-box"
            options={variants}
            getOptionLabel={(option: Directory) =>
                "#" + option.id + " - " + option.title
            }
            getOptionSelected={(option: Directory, value: Directory) => {
                return option.id === value.id;
            }}

            value={filter}
            onChange={handleFilterChange}

            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Налогооблажение"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
};


export const NdsFilter = (props: any) => {
    const { value, variants, userValue, users, onChange } = props;

    const [filter, setFilter] = React.useState<Directory>(null);

    if (value && filter == null) {
        const d = variants.find(function (element: Directory) {
            return element.id == value;
        });

        if (d) {
            setFilter(d);
        }
    }

    const handleFilterChange = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        device: Directory
    ) => {
        setFilter(device);

        if (device) {
            onChange(device.id);
        } else {
            onChange("");
        }
    };

    return (
        <Autocomplete
            id="nds-box"
            options={variants}
            getOptionLabel={(option: Directory) =>
                "#" + option.id + " - " + option.title
            }
            getOptionSelected={(option: Directory, value: Directory) => {
                return option.id === value.id;
            }}

            value={filter}
            onChange={handleFilterChange}

            renderInput={(params) => (
                <TextField
                    {...params}
                    label="НДС"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
};