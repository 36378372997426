import { connect } from "react-redux";
import { deviceSettingUpdateRequestStartAction } from "../../store/DeviceSetting/DeviceSettingAction";
import { DeviceSettingUpdateForm } from "../../store/DeviceSetting/DeviceSettingModel";
import { State } from "../../store/reducers";
import { userDirectoryRequestStartAction } from "../../store/User/UserAction";
import { FormComponent } from "./form.component";

const mapStateToProps = (state: State) => ({
  isAdmin: state.auth.isAdmin,
  deviceSettingUpdating: state.deviceSetting.updating
});

const mapDispatchToProps = (dispatch: any) => ({
  onRequestDeviceSettingUpdate: (id: number, form: DeviceSettingUpdateForm) =>
    dispatch(deviceSettingUpdateRequestStartAction(id, form)),
  onRequestUserDirectory: () => dispatch(userDirectoryRequestStartAction())
});

export const DeviceSettingUpdateContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FormComponent);
