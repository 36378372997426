import { BaseAction } from "../Common/CommonModel";
import { Settings } from "./SettingsModel";

export type SettingsState = Settings;

export const settingsReducer = (
    state: SettingsState = {
        defaultPage: "/",
        loginPage: "/auth/sign-in",
        dateFormat: "YYYY-MM-DD",
        timeFormat: "HH:mm:ss",
        timeZone: "Asia/Novosibirsk",
    },
    action: BaseAction
) => {
    switch (action.type) {
    }

    return state;
};
