import {
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import React from "react";
import { UserCreateForm } from "../../store/User/UserModel";

interface Props {
  userCreating: boolean;
  onRequestUserCreate: (form: UserCreateForm) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      marginLeft: 0,
      marginRight: 0,
      width: "100%",
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
  })
);

export const FormComponent = (props: any) => {
  const classes = useStyles();
  const { handleClose, userCreating, onRequestUserCreate } = props;

  const createForm = {
    login: "",
    password: "",
    email: "",
    phone: "",
    is_admin: false,
    first_name: "",
    last_name: "",
  };

  const [fields, setFields] = React.useState<UserCreateForm>(createForm);

  const handleChangeField =
    (prop: keyof UserCreateForm) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFields({ ...fields, [prop]: event.target.value });
    };

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFields({ ...fields, [event.target.name]: event.target.checked });
  };

  // ***

  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  // ***

  const handleStartCreate = () => {
    onRequestUserCreate(fields);
    handleClose();
  };

  return (
    <>
      <DialogTitle>Создание пользователя</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <TextField
                value={fields.login}
                label="Логин"
                onChange={handleChangeField("login")}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="standard-adornment-password">
                Password
              </InputLabel>
              <Input
                id="standard-adornment-password"
                type={showPassword ? "text" : "password"}
                value={fields.password}
                onChange={handleChangeField("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <TextField
                value={fields.last_name}
                label="Фамилия"
                onChange={handleChangeField("last_name")}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <TextField
                value={fields.first_name}
                label="Имя"
                onChange={handleChangeField("first_name")}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <TextField
                value={fields.email}
                label="E-mail"
                onChange={handleChangeField("email")}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <TextField
                value={fields.phone}
                label="Телефон"
                onChange={handleChangeField("phone")}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={fields.is_admin}
                  onChange={handleChangeCheckbox}
                  name="is_admin"
                />
              }
              label="Админ?"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {!userCreating && (
          <>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleStartCreate}
              variant="contained"
              color="primary"
            >
              Сохранить
            </Button>
          </>
        )}

        {userCreating && <CircularProgress />}
      </DialogActions>
    </>
  );
};
