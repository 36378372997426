import { connect } from "react-redux";
import {
  changePathAction,
  toggleNavAction,
} from "../../store/Common/CommonActions";
import { State } from "../../store/reducers";
import { NavComponent } from "./nav.component";

const mapStateToProps = (state: State) => ({
  isAdmin: state.auth.isAdmin,
  nav: state.nav,
});

const mapDispatchToProps = (dispatch: any) => ({
  onToggleNav: (isOpen: boolean) => dispatch(toggleNavAction(isOpen)),
  onChangePath: (path: string) => dispatch(changePathAction(path)),
});

export const NavContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NavComponent);
