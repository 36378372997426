/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { createStyles, FormControl, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: 0,
      minWidth: 150,
      width: "100%",
    },
  })
);

export const ProjectAutoFilter = (props: any) => {
  const classes = useStyles();
  const { value, onChange } = props;
  const [filter, setFilter] = React.useState<string | null>(projects[0].title);

 
  return (
    <FormControl className={classes.formControl}>
      <Autocomplete
        id="combo-box-demo"
        options={projects}
        getOptionLabel={(option) => option.title}
        onChange={(event: any, newValue: string | null) => {
          if (newValue==null){
            onChange(0);
          } else {
          setFilter(newValue);
          onChange(newValue['code']);
          }
        }}
        renderInput={(params) => <TextField {...params} label="Проект" margin="normal" />}
      />
    </FormControl>
  );
}

const projects = [
  { title: "Автомойка 150bar", code: 2 },
  { title: "Фискализатор", code: 3 },
];
