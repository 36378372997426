import { BaseAction, Directory, Pagination } from "../Common/CommonModel";
import { DeviceSetting, DeviceSettingFilter } from "./DeviceSettingModel";
import * as types from "./DeviceSettingTypes";

export type DeviceSettingState = {
  list: {
    loading: boolean;
    items: DeviceSetting[];
    pagination: Pagination;
  };
  directory: {
    loading: boolean;
    items: Directory[];
  };
  updating: boolean;
  creating: boolean;
  filter: DeviceSettingFilter;
};

const defaultDeviceSettingPagination = {
  page: 0,
  page_count: 0,
  per_page: 10,
  total_count: 0,
};

export const defaultDeviceSettingState: DeviceSettingState = {
  list: {
    loading: false,
    items: [],
    pagination: defaultDeviceSettingPagination,
  },
  directory: {
    loading: false,
    items: [],
  },
  updating: false,
  creating: false,
  filter: {
    page: 0,
    per_page: defaultDeviceSettingPagination.per_page,
    order_by: "id",
    order: "asc",
    user_id: null,
    key: "",
    controller: null
  },
};

export const deviceSettingReducer = (
  state: DeviceSettingState = defaultDeviceSettingState,
  action: BaseAction
) => {
  switch (action.type) {
    case types.GET_DEVICE_SETTING_LIST_START:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };

    case types.GET_DEVICE_SETTING_LIST_COMPLETED:
      return {
        ...state,
        list: {
          loading: false,
          items: action.payload.items,
          pagination: action.payload.pagination,
        },
      };

    case types.GET_DEVICE_SETTING_LIST_FAILED:
      return {
        ...state,
        list: {
          loading: false,
          items: [],
          pagination: defaultDeviceSettingPagination,
        },
      };

    // ***

    case types.GET_DEVICE_SETTING_DIRECTORY_START:
      return {
        ...state,
        directory: {
          items: [],
          loading: true,
        },
      };

    case types.GET_DEVICE_SETTING_DIRECTORY_COMPLETED:
      return {
        ...state,
        directory: {
          loading: false,
          items: action.payload.items,
        },
      };

    case types.GET_DEVICE_SETTING_DIRECTORY_FAILED:
      return {
        ...state,
        directory: {
          loading: false,
          items: [],
        },
      };

    // ***

    case types.UPDATE_DEVICE_SETTING_START:
      return {
        ...state,
        updating: true,
      };

    case types.UPDATE_DEVICE_SETTING_COMPLETED:
      return {
        ...state,
        updating: false,
      };

    case types.UPDATE_DEVICE_SETTING_FAILED:
      return {
        ...state,
        updating: false,
      };

    case types.CREATE_DEVICE_SETTING_START:
      return {
        ...state,
        creating: true,
      };

    case types.CREATE_DEVICE_SETTING_COMPLETED:
      return {
        ...state,
        creating: false,
      };

    case types.CREATE_DEVICE_SETTING_FAILED:
      return {
        ...state,
        creating: false,
      };
  }

  return state;
};
