import agentInstance from "../../api/agent";
import { Receipt } from "./ReceiptModel";

// ***

export const getReceiptsList = (
  prjId: Number,
  params: {}
): Promise<Receipt[]> => {
  return new Promise<Receipt[]>((resolve) => {
    resolve(
      agentInstance(prjId).get(`receipts`, {
        params: params["filter"],
      })
    );
  });
};

// ***

export const getPublicReceiptItem = (
  prjId: Number,
  deviceID: Number,
  checkID: Number
): Promise<Receipt[]> => {
  return new Promise<Receipt[]>((resolve) => {
    resolve(agentInstance(prjId).get(`receipts/` + deviceID + `/` + checkID));
  });
};

// ***

export const getReceiptLogs = (
  prjId: Number,
  receiptId: Number
): Promise<Receipt[]> => {
  return new Promise<Receipt[]>((resolve) => {
    resolve(agentInstance(prjId).get(`receipt_log/` + receiptId));
  });
};
