import agentInstance from "../../api/agent";
import { User } from "./UserModel";

export const getUserDirectory = (
  prjId: Number,
  params: {}
): Promise<User[]> => {
  return new Promise<User[]>((resolve) => {
    resolve(agentInstance(prjId).get(`users/dir`));
  });
};

export const getUsersList = (prjId: Number, params: {}): Promise<User[]> => {
  return new Promise<User[]>((resolve) => {
    resolve(
      agentInstance(prjId).get(`users`, {
        params: params["filter"],
      })
    );
  });
};

export const postUserCreate = (prjId: Number, params: {}): Promise<any> => {
  return new Promise<User[]>((resolve) => {
    resolve(agentInstance(prjId).post(`users`, params["form"]));
  });
};

export const postUserUpdate = (prjId: Number, params: {}): Promise<any> => {
  return new Promise<User[]>((resolve) => {
    resolve(agentInstance(prjId).post(`users/` + params["id"], params["form"]));
  });
};
