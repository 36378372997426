import { useCallback, useEffect } from "react";
import { DeviceFilter } from "../store/Device/DeviceModel";
import { ReceiptFilter } from "../store/Receipt/ReceiptModel";

export default function useFilter(filter, onFilterChange) {
  useEffect(() => {
    onFilterChange(filter);
  }, []);

  const handleChangeOrder =useCallback(
    (property: string) => (event: React.MouseEvent<unknown>) => {
      if (filter.order_by !== property) {
        filter.order = "asc";
        filter.order_by = property;
      } else {
        filter.order = filter.order === "asc" ? "desc" : "asc";
      }

      onFilterChange(filter);
    },[]);

    const handleChangeNumberField = useCallback((value: number, idFilter: string, filter: ReceiptFilter|DeviceFilter ) => {
      if (idFilter == "controller") {
        filter.controller = value;
        handleChangeValue({
          page: 0,
          controller: value ? value : -1,
        })
      }
      else if (idFilter == "check_id") {
        (filter as ReceiptFilter).check_id = value;
        handleChangeValue({
          page: 0,
          check_id: value ? value : -1,
        })
      }
    },[]);

  const handleChangeValue = useCallback((changes: Object) => 
  {
    
    console.log("Было:", filter);

    filter = { ...filter, ...changes };

    console.log("Стало:", filter);

    onFilterChange(filter);
  },[]);

  return [filter, handleChangeOrder, handleChangeValue, handleChangeNumberField];
}
