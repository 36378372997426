import { BaseAction } from "../Common/CommonModel";
import * as types from './AuthTypes';

export const signOutAction = (): BaseAction => ({
    type: types.SIGN_OUT,
    payload: null,
});

export const signInAction = (email: string, password: string): BaseAction => ({
    type: types.SIGN_IN_START,
    payload: {
        email,
        password,
    },
});

export const signInCompletedAction = (token: string, isAdmin: boolean): BaseAction => ({
    type: types.SIGN_IN_COMPLETED,
    payload: {
        token: token,
        isAdmin: isAdmin,
    },
});

export const signInFailedAction = (): BaseAction => ({
    type: types.SIGN_IN_FAILED,
    payload: null,
});



