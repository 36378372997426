import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import { Directory } from "../../store/Common/CommonModel";

export const UserFilter = (props: any) => {
  const { value, variants, onChange } = props;

  const [filter, setFilter] = React.useState<Directory>(null);

  if (value && filter == null) {
    const d = variants.find(function (element: Directory) {
      return element.id == value;
    });

    if (d) {
      setFilter(d);
    }
  }

  const handleFilterChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    device: Directory
  ) => {
    setFilter(device);

    if (device) {
      onChange(device.id);
    } else {
      onChange("");
    }
  };

  return (
    <Autocomplete
      id="device-box"
      options={variants}
      getOptionLabel={(option: Directory) =>
        "#" + option.id + " - " + option.title
      }
      getOptionSelected={(option: Directory, value: Directory) => {
        return option.id === value.id;
      }}
      value={filter}
      onChange={handleFilterChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Владелец"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
