import { connect } from "react-redux";
import {
  accountDeleteRequestStartAction,
  accountsRequestStartAction,
} from "../../store/Account/AccountAction";
import { AccountFilter } from "../../store/Account/AccountModel";
import { State } from "../../store/reducers";
import { PageComponent } from "./page.component";

const mapStateToProps = (state: State) => ({
  accountsLoading: state.account.list.loading,
  accounts: state.account.list.items,
  pagination: state.account.list.pagination,
  filter: state.account.filter,
  settings: state.settings,
});

const mapDispatchToProps = (dispatch: any) => ({
  onRequestAccountList: (filter: AccountFilter) =>
    dispatch(accountsRequestStartAction(filter)),
  onRequestAccountDelete: (id: number) =>
    dispatch(accountDeleteRequestStartAction(id)),
});

export const PageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PageComponent);
