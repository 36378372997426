import { call, put, select, takeEvery } from "redux-saga/effects";
import { BaseAction } from "../Common/CommonModel";
import { State } from "../reducers";
import { enqueueSnackbar } from "../Snackbar/SnackbarAction";
import {
  accountCreateRequestCompletedAction,
  accountCreateRequestFailedAction,
  accountDeleteRequestCompletedAction,
  accountDeleteRequestFailedAction,
  accountDirectoryRequestCompletedAction,
  accountDirectoryRequestFailedAction,
  accountsRequestCompletedAction,
  accountsRequestFailedAction,
  accountsRequestStartAction,
  accountUpdateRequestCompletedAction,
  accountUpdateRequestFailedAction,
  providerDirectoryRequestCompletedAction,
  providerDirectoryRequestFailedAction,
  ndsDirectoryRequestCompletedAction,
  ndsDirectoryRequestFailedAction,
  taxationDirectoryRequestCompletedAction,
  taxationDirectoryRequestFailedAction
} from "./AccountAction";
import {
  deleteAccount,
  getAccountDirectory,
  getNdsDirectory,
  getTaxationDirectory,
  getProviderDirectory,
  getAccountsList,
  postAccountCreate,
  postAccountUpdate,
} from "./AccountApis";
import * as types from "./AccountTypes";
import {getDeviceSettingDirectory} from "../DeviceSetting/DeviceSettingApis";
import {
  deviceSettingDirectoryRequestCompletedAction,
  deviceSettingDirectoryRequestFailedAction
} from "../DeviceSetting/DeviceSettingAction";

export function* watchAccountRequestStart() {
  yield takeEvery(types.GET_ACCOUNT_LIST_START, requestReceiptList);
  yield takeEvery(types.GET_ACCOUNT_DIRECTORY_START, requestAccountDirectory);
  yield takeEvery(types.GET_NDS_DIRECTORY_START, requestNdsDirectory);
  yield takeEvery(types.GET_PROVIDER_DIRECTORY_START, requestProviderDirectory);
  yield takeEvery(types.GET_TAXATION_DIRECTORY_START, requestTaxationDirectory);
  yield takeEvery(types.CREATE_ACCOUNT_START, requestCreateAccount);
  yield takeEvery(types.UPDATE_ACCOUNT_START, requestUpdateAccount);
  yield takeEvery(types.DELETE_ACCOUNT_START, requestDeleteAccount);
}

const getFilter = (state: State) => state.account.filter;

function* requestReceiptList(action: BaseAction) {
  try {
    const prjId = 0;
    const response = yield call(getAccountsList, prjId, {
      ...action.payload,
      filter: {
        ...action.payload.filter,
        page: action.payload.filter.page + 1,
      },
    });

    if (response.status != 200) {
      throw { message: "Неизвестная ошибка" };
    }

    yield put(
      accountsRequestCompletedAction(response.data["list"], {
        ...response.data["pagination"],
        page: response.data["pagination"].page - 1,
      })
    );
  } catch (e) {
    yield put(accountsRequestFailedAction());

    const notification = {
      message: "Не удалось получить список реквизитов (" + e.message + ")",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    };

    yield put(enqueueSnackbar(notification));
  }
}

// ***

function* requestAccountDirectory(action: BaseAction) {
  try {
    const prjId = 0;
    const response = yield call(getAccountDirectory, prjId, action.payload);

    if (response.status != 200) {
      throw { message: "Неизвестная ошибка" };
    }

    yield put(accountDirectoryRequestCompletedAction(response.data));
  } catch (e) {
    yield put(accountDirectoryRequestFailedAction());

    const notification = {
      message: "Не удалось получить справочник устройств (" + e.message + ")",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    };

    yield put(enqueueSnackbar(notification));
  }
}

// ***

function* requestNdsDirectory(action: BaseAction) {
  try {
    const prjId = 0;
    const response = yield call(getNdsDirectory, prjId, action.payload);

    if (response.status != 200) {
      throw { message: "Неизвестная ошибка" };
    }

    yield put(ndsDirectoryRequestCompletedAction(response.data));
  } catch (e) {
    yield put(ndsDirectoryRequestFailedAction());

    const notification = {
      message: "Не удалось получить справочник устройств (" + e.message + ")",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    };

    yield put(enqueueSnackbar(notification));
  }
}

function* requestTaxationDirectory(action: BaseAction) {
  try {
    const prjId = 0;
    const response = yield call(getTaxationDirectory, prjId, action.payload);

    if (response.status != 200) {
      throw { message: "Неизвестная ошибка" };
    }

    yield put(taxationDirectoryRequestCompletedAction(response.data));
  } catch (e) {
    yield put(taxationDirectoryRequestFailedAction());

    const notification = {
      message: "Не удалось получить справочник устройств (" + e.message + ")",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    };

    yield put(enqueueSnackbar(notification));
  }
}

function* requestProviderDirectory(action: BaseAction) {
  try {
    const prjId = 0;
    const response = yield call(getProviderDirectory, prjId, action.payload);

    if (response.status != 200) {
      throw { message: "Неизвестная ошибка" };
    }

    yield put(providerDirectoryRequestCompletedAction(response.data));
  } catch (e) {
    yield put(providerDirectoryRequestFailedAction());

    const notification = {
      message: "Не удалось получить справочник устройств (" + e.message + ")",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    };

    yield put(enqueueSnackbar(notification));
  }
}


function* requestCreateAccount(action: BaseAction) {
  try {
    const prjId = 0;
    const response = yield call(postAccountCreate, prjId, action.payload);

    if (response.status != 200) {
      throw { message: "Неизвестная ошибка" };
    }

    yield put(accountCreateRequestCompletedAction());
  } catch (e) {
    yield put(accountCreateRequestFailedAction());

    const notification = {
      message: "Не удалось создать реквизиты (" + e.message + ")",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    };

    yield put(enqueueSnackbar(notification));
  }

  const filter = yield select(getFilter);
  yield put(accountsRequestStartAction(filter));
}

// ***

function* requestUpdateAccount(action: BaseAction) {
  try {
    const prjId = 0;
    const response = yield call(postAccountUpdate, prjId, action.payload);

    if (response.status != 200) {
      throw { message: "Неизвестная ошибка" };
    }

    yield put(accountUpdateRequestCompletedAction());
  } catch (e) {
    yield put(accountUpdateRequestFailedAction());

    const notification = {
      message: "Не удалось сохранить изменения (" + e.message + ")",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    };

    yield put(enqueueSnackbar(notification));
  }

  const filter = yield select(getFilter);
  yield put(accountsRequestStartAction(filter));
}

// ***

function* requestDeleteAccount(action: BaseAction) {
  try {
    const prjId = 0;
    const response = yield call(deleteAccount, prjId, action.payload);

    if (response.status != 200) {
      throw { message: "Неизвестная ошибка" };
    }

    yield put(accountDeleteRequestCompletedAction());
  } catch (e) {
    yield put(accountDeleteRequestFailedAction());

    const notification = {
      message: "Не удалось сохранить изменения (" + e.message + ")",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    };

    yield put(enqueueSnackbar(notification));
  }

  const filter = yield select(getFilter);
  yield put(accountsRequestStartAction(filter));
}
