import {
  Divider,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import {
  createStyles,
  lighten,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: 0,
      minWidth: 140,
      width: "100%",
    },
    optionGroup: {
      background: "white",
    },
    error: {
      backgroundColor: lighten(theme.palette.error.light, 0.75),
    },
    event: {
      backgroundColor: lighten(theme.palette.info.light, 0.9),
    },
    debug: {},
    command: {
      backgroundColor: lighten(theme.palette.warning.light, 0.9),
    },
  })
);

export const AmountFilter = (props: any) => {
  const classes = useStyles();
  const { amount_to, amount_from, onChange } = props;
  const [amountTo, setAmountToChange] = React.useState(amount_to);
  const [amountFrom, setAmountFromChange] = React.useState(amount_from);

  const handleAmountToChange = (value: Number) => {
    setAmountToChange(value);
    console.log('amountTo ' + value + 'amountFrom' + amountFrom);
    handleChange(amountFrom, value);
  };

  const handleAmountFromChange = (value: Number) => {
    setAmountFromChange(value);
    console.log('amountTo ' + amountTo + 'amountFrom' + value);
    handleChange(value, amountTo);
  };

  const handleChange = (fAmountFrom, fAmountTo) => {
    if (Number(fAmountFrom) > Number(fAmountTo)) return;
    onChange({
      page:0,
      amount_from: Number(fAmountFrom),
      amount_to: Number(fAmountTo),
    });
  };

  return (
    <FormControl className={classes.formControl}>

      <TextField
        id="standard-number"
        type="number"
        label='Сумма от:'
        InputProps={{ inputProps: { min: 0} }}
       // value={amountFrom}
        onChange={(event: any) => {
          console.log(event.target.value);
          handleAmountFromChange(event.target.value);}
        }
      />
      <TextField
        id="standard-number"
        type="number"
        InputProps={{ inputProps: { min: 0} }}
        label='до:'
        //value={amountTo}
        onChange={(event: any) => {
          console.log(event.target.value);
          handleAmountToChange(event.target.value);}
        }
      />
    </FormControl>
  );
};
