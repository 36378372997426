export const rfc3339 = (d: Date, onlyDate: boolean) => {
  function pad(n: number) {
    return n < 10 ? "0" + n : n;
  }

  function timezoneOffset(offset) {
    var sign;
    if (offset === 0) {
      return "Z";
    }
    sign = offset > 0 ? "-" : "+";
    offset = Math.abs(offset);
    return sign + pad(Math.floor(offset / 60)) + ":" + pad(offset % 60);
  }

  var fullYear = d.getFullYear(),
    month = pad(d.getMonth() + 1),
    date = pad(d.getDate()),
    hours = pad(d.getHours()),
    minutes = pad(d.getMinutes()),
    seconds = pad(d.getSeconds()),
    timezone = timezoneOffset(d.getTimezoneOffset());

  if (onlyDate) {
    hours = pad(0);
    minutes = pad(0);
    seconds = pad(0);
  }

  return (
    fullYear +
    "-" +
    month +
    "-" +
    date +
    "T" +
    hours +
    ":" +
    minutes +
    ":" +
    seconds +
    timezone
  );
};
