import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import EmptyLayout from "./layouts/empty";
import MainLayout from "./layouts/main";
import { PageContainer as AccountsPage } from "./pages/accounts/page.container";
import { PageContainer as DevicesPage } from "./pages/devices/page.container";
import { PageContainer as DevicesSettingsPage } from "./pages/devices-settings/page.container";
import { PageContainer as Error404Page } from "./pages/error404/page.container";
import { PageContainer as HomePage } from "./pages/home/page.container";
import { PageContainer as PublicReceiptPage } from "./pages/public-receipt/page.container";
import { PageContainer as ReceiptsPage } from "./pages/receipts/page.container";
import { PageContainer as SignInPage } from "./pages/sign-in/page.container";
import { PageContainer as UsersPage } from "./pages/users/page.container";

const MainRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <MainLayout>
          <Component {...props} />
        </MainLayout>
      )}
    />
  );
};

const EmptyRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <EmptyLayout>
          <Component {...props} />
        </EmptyLayout>
      )}
    />
  );
};

export default () => (
  <Router>
    <Switch>
      <EmptyRoute path="/c/:deviceID/:checkID" component={PublicReceiptPage} />

      <MainRoute path="/" exact component={HomePage} />

      <MainRoute path="/devices" component={DevicesPage} />
      <MainRoute path="/devices_settings" component={DevicesSettingsPage} />
      <MainRoute path="/receipts" component={ReceiptsPage} />
      <MainRoute path="/users" component={UsersPage} />
      <MainRoute path="/accounts" component={AccountsPage} />

      <EmptyRoute path="/auth/sign-in" component={SignInPage} />

      <EmptyRoute component={Error404Page} />
    </Switch>
  </Router>
);
