import { connect } from "react-redux";
import { accountUpdateRequestStartAction } from "../../store/Account/AccountAction";
import { AccountCreateForm } from "../../store/Account/AccountModel";
import { State } from "../../store/reducers";
import { userDirectoryRequestStartAction } from "../../store/User/UserAction";
import { ndsDirectoryRequestStartAction } from "../../store/Account/AccountAction";
import { providerDirectoryRequestStartAction } from "../../store/Account/AccountAction";
import { taxationDirectoryRequestStartAction } from "../../store/Account/AccountAction";
import { FormComponent } from "./form.component";

const mapStateToProps = (state: State) => ({
  isAdmin: state.auth.isAdmin,
  accountCreating: state.account.creating,
  userDirectory: state.user.directory.items,
  ndsDirectory: state.account.nds_directory.items,
  providerDirectory: state.account.provider_directory.items,
  taxationDirectory: state.account.taxation_directory.items
});

const mapDispatchToProps = (dispatch: any) => ({
  onRequestAccountUpdate: (id: number, form: AccountCreateForm) =>
    dispatch(accountUpdateRequestStartAction(id, form)),
  onRequestUserDirectory: () => dispatch(userDirectoryRequestStartAction()),
  onRequestNdsDirectory: () => dispatch(ndsDirectoryRequestStartAction()),
  onRequestProviderDirectory: () => dispatch(providerDirectoryRequestStartAction()),
  onRequestTaxationDirectory: () => dispatch(taxationDirectoryRequestStartAction())
});

export const FormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FormComponent);
