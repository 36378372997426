import axios from "axios";
import { API_BASE } from '../constants/env';
import { store } from '../store';
import * as commontypes from '../store/Common/CommonTypes';

var client = axios.create({
    baseURL: API_BASE,
    timeout: 30000,
    headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
    },
});

const agentInstance = (prjId: Number) => {
    if (store.getState().auth.isAuthenticated) {
        client.defaults.headers.common['x-access-tokens'] = store.getState().auth.accessToken;
    }
    
    return client;
}

client.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401) {
        store.dispatch({
            type: commontypes.API_ERROR_401,
            payload: error,
        })
        return Promise.reject(error);
    }
    return error;
});

export default agentInstance;
