import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { ReceiptLog } from "../store/Receipt/ReceiptModel";
import { State } from "../store/reducers";
import { Settings } from "../store/Settings/SettingsModel";
import DateTime from "./formatters/date-time";
import ReceiptStatus from "./receipt-status";

interface Props {
  settings: Settings;
  receiptId: number;
  loading: boolean;
  items: ReceiptLog[];
}

const ReceiptLogsComponent: React.FunctionComponent<Props> = (props) => {
  const { settings, loading, items } = props;

  return (
    <>
      {loading == false && (
        <>
          <Table size="small">
            <TableHead>
              <TableRow hover>
                <TableCell align="left">Дата</TableCell>
                <TableCell align="center">Link/ID</TableCell>
                <TableCell align="center">Статус</TableCell>
                <TableCell align="center">Сообщение</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items &&
                0 < items.length &&
                items.map((item) => (
                  <TableRow hover>
                    <TableCell align="left">
                      <DateTime
                        dateFormat={settings.dateFormat}
                        timeFormat={settings.timeFormat}
                        timeZone={settings.timeZone}
                        dateTime={item.cur_datetime}
                      />
                    </TableCell>
                    <TableCell align="center">
                       {item.link}
                    </TableCell>
                    <TableCell align="center">
                      <ReceiptStatus value={item.status} />
                    </TableCell>
                    <TableCell align="center">
                      {item.message}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </>
      )}
      {loading && <CircularProgress color="inherit" />}
    </>
  );
};

const mapStateToProps = (state: State) => ({
  settings: state.settings,
  receiptId: state.receipt.logs.receiptId,
  loading: state.receipt.logs.loading,
  items: state.receipt.logs.items,
});

const mapDispatchToProps = (dispatch: any) => ({});

export const ReceiptLogs = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReceiptLogsComponent);
