export const GET_ACCOUNT_LIST_START = "GET_ACCOUNT_LIST - Start";
export const GET_ACCOUNT_LIST_COMPLETED = "GET_ACCOUNT_LIST - Completed";
export const GET_ACCOUNT_LIST_FAILED = "GET_ACCOUNT_LIST - Failed";

export const GET_ACCOUNT_DIRECTORY_START = "GET_ACCOUNT_DIRECTORY - Start";
export const GET_ACCOUNT_DIRECTORY_COMPLETED = "GET_ACCOUNT_DIRECTORY - Completed";
export const GET_ACCOUNT_DIRECTORY_FAILED = "GET_ACCOUNT_DIRECTORY - Failed";

export const CREATE_ACCOUNT_START = "CREATE_ACCOUNT - Start";
export const CREATE_ACCOUNT_COMPLETED = "CREATE_ACCOUNT - Completed";
export const CREATE_ACCOUNT_FAILED = "CREATE_ACCOUNT - Failed";

export const UPDATE_ACCOUNT_START = "UPDATE_ACCOUNT - Start";
export const UPDATE_ACCOUNT_COMPLETED = "UPDATE_ACCOUNT - Completed";
export const UPDATE_ACCOUNT_FAILED = "UPDATE_ACCOUNT - Failed";

export const DELETE_ACCOUNT_START = "DELETE_ACCOUNT - Start";
export const DELETE_ACCOUNT_COMPLETED = "DELETE_ACCOUNT - Completed";
export const DELETE_ACCOUNT_FAILED = "DELETE_ACCOUNT - Failed";

export const GET_NDS_DIRECTORY_START = "GET_NDS_DIRECTORY - Start";
export const GET_NDS_DIRECTORY_COMPLETED = "GET_NDS_DIRECTORY - Completed";
export const GET_NDS_DIRECTORY_FAILED = "GET_NDS_DIRECTORY - Failed";

export const GET_TAXATION_DIRECTORY_START = "GET_TAXATION_DIRECTORY - Start";
export const GET_TAXATION_DIRECTORY_COMPLETED = "GET_TAXATION_DIRECTORY - Completed";
export const GET_TAXATION_DIRECTORY_FAILED = "GET_TAXATION_DIRECTORY - Failed";

export const GET_PROVIDER_DIRECTORY_START = "GET_PROVIDER_DIRECTORY - Start";
export const GET_PROVIDER_DIRECTORY_COMPLETED = "GET_PROVIDER_DIRECTORY - Completed";
export const GET_PROVIDER_DIRECTORY_FAILED = "GET_PROVIDER_DIRECTORY - Failed";
