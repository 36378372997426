import { BaseAction, Directory, Pagination } from "../Common/CommonModel";
import {
  Account,
  AccountCreateForm,
  AccountFilter,
  AccountUpdateForm,
} from "./AccountModel";
import * as types from "./AccountTypes";

export const accountsRequestStartAction = (
  filter: AccountFilter
): BaseAction => ({
  type: types.GET_ACCOUNT_LIST_START,
  payload: {
    filter: filter,
  },
});

export const accountsRequestCompletedAction = (
  list: Account[],
  pagination: Pagination
): BaseAction => ({
  type: types.GET_ACCOUNT_LIST_COMPLETED,
  payload: {
    items: list,
    pagination: pagination,
  },
});

export const accountsRequestFailedAction = (): BaseAction => ({
  type: types.GET_ACCOUNT_LIST_FAILED,
  payload: {},
});

// ***

export const accountDirectoryRequestStartAction = (): BaseAction => ({
  type: types.GET_ACCOUNT_DIRECTORY_START,
  payload: {},
});

export const accountDirectoryRequestCompletedAction = (
  list: Directory[]
): BaseAction => ({
  type: types.GET_ACCOUNT_DIRECTORY_COMPLETED,
  payload: {
    items: list,
  },
});

export const accountDirectoryRequestFailedAction = (): BaseAction => ({
  type: types.GET_ACCOUNT_DIRECTORY_FAILED,
  payload: {},
});

// ***

export const accountCreateRequestStartAction = (
  form: AccountCreateForm
): BaseAction => ({
  type: types.CREATE_ACCOUNT_START,
  payload: {
    form: form,
  },
});

export const accountCreateRequestCompletedAction = (): BaseAction => ({
  type: types.CREATE_ACCOUNT_COMPLETED,
  payload: {},
});

export const accountCreateRequestFailedAction = (): BaseAction => ({
  type: types.CREATE_ACCOUNT_FAILED,
  payload: {},
});

// ***

export const accountUpdateRequestStartAction = (
  id: number,
  form: AccountUpdateForm
): BaseAction => ({
  type: types.UPDATE_ACCOUNT_START,
  payload: {
    id: id,
    form: form,
  },
});

export const accountUpdateRequestCompletedAction = (): BaseAction => ({
  type: types.UPDATE_ACCOUNT_COMPLETED,
  payload: {},
});

export const accountUpdateRequestFailedAction = (): BaseAction => ({
  type: types.UPDATE_ACCOUNT_FAILED,
  payload: {},
});

// ***

export const accountDeleteRequestStartAction = (id: number): BaseAction => ({
  type: types.DELETE_ACCOUNT_START,
  payload: {
    id: id,
  },
});

export const accountDeleteRequestCompletedAction = (): BaseAction => ({
  type: types.DELETE_ACCOUNT_COMPLETED,
  payload: {},
});

export const accountDeleteRequestFailedAction = (): BaseAction => ({
  type: types.DELETE_ACCOUNT_FAILED,
  payload: {},
});



export const ndsDirectoryRequestStartAction = (): BaseAction => ({
  type: types.GET_NDS_DIRECTORY_START,
  payload: {},
});

export const ndsDirectoryRequestCompletedAction = (
    list: Directory[]
): BaseAction => ({
  type: types.GET_NDS_DIRECTORY_COMPLETED,
  payload: {
    items: list,
  },
});

export const ndsDirectoryRequestFailedAction = (): BaseAction => ({
  type: types.GET_NDS_DIRECTORY_FAILED,
  payload: {},
});

export const providerDirectoryRequestStartAction = (): BaseAction => ({
  type: types.GET_PROVIDER_DIRECTORY_START,
  payload: {},
});

export const providerDirectoryRequestCompletedAction = (
    list: Directory[]
): BaseAction => ({
  type: types.GET_PROVIDER_DIRECTORY_COMPLETED,
  payload: {
    items: list,
  },
});

export const providerDirectoryRequestFailedAction = (): BaseAction => ({
  type: types.GET_PROVIDER_DIRECTORY_FAILED,
  payload: {},
});

export const taxationDirectoryRequestStartAction = (): BaseAction => ({
  type: types.GET_TAXATION_DIRECTORY_START,
  payload: {},
});

export const taxationDirectoryRequestCompletedAction = (
    list: Directory[]
): BaseAction => ({
  type: types.GET_TAXATION_DIRECTORY_COMPLETED,
  payload: {
    items: list,
  },
});

export const taxationDirectoryRequestFailedAction = (): BaseAction => ({
  type: types.GET_TAXATION_DIRECTORY_FAILED,
  payload: {},
});
