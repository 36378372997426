import { rfc3339 } from "../../utils";
import { BaseAction, Pagination } from "../Common/CommonModel";
import { Receipt, ReceiptFilter, ReceiptLog } from "./ReceiptModel";
import * as types from "./ReceiptTypes";

export type ReceiptState = {
  list: {
    loading: boolean;
    items: Receipt[];
    pagination: Pagination;
  };
  public: {
    loading: boolean;
    status: number;
    item: Receipt;
  };
  filter: ReceiptFilter;
  logs: {
    receiptId: number;
    loading: boolean;
    items: ReceiptLog[];
  };
};

const defaultReceiptPagination = {
  page: 0,
  page_count: 0,
  per_page: 10,
  total_count: 0,
};

export const defaultReceiptState: ReceiptState = {
  list: {
    loading: false,
    items: [],
    pagination: defaultReceiptPagination,
  },
  public: {
    loading: false,
    status: 0,
    item: null,
  },
  filter: {
    page: 0,
    per_page: defaultReceiptPagination.per_page,
    order_by: "id",
    order: "asc",
    check_id: -1,
    controller: -1,
    amount_from: 0,
    amount_to: 0,
    status: 0,
    min_create_date: rfc3339(new Date(), true),
    max_create_date: rfc3339(new Date(), true),
  },
  logs: {
    receiptId: null,
    loading: false,
    items: [],
  },
};

export const receiptReducer = (
  state: ReceiptState = defaultReceiptState,
  action: BaseAction
) => {
  switch (action.type) {
    case types.GET_RECEIPT_LIST_START:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };

    case types.GET_RECEIPT_LIST_COMPLETED:
      return {
        ...state,
        list: {
          loading: false,
          items: action.payload.items,
          pagination: action.payload.pagination,
        },
      };

    case types.GET_RECEIPT_LIST_FAILED:
      return {
        ...state,
        list: {
          loading: false,
          items: [],
          pagination: defaultReceiptPagination,
        },
      };

    // ***

    case types.GET_PUBLIC_RECEIPT_ITEM_START:
      return {
        ...state,
        public: {
          loading: true,
          status: 0,
          item: null,
        },
      };

    case types.GET_PUBLIC_RECEIPT_ITEM_COMPLETED:
      return {
        ...state,
        public: {
          loading: false,
          status: action.payload.status,
          item: action.payload.item,
        },
      };

    case types.GET_PUBLIC_RECEIPT_ITEM_FAILED:
      return {
        ...state,
        public: {
          loading: false,
          status: 0,
          item: null,
        },
      };

    // ***

    case types.GET_RECEIPT_LOG_START:
      return {
        ...state,
        logs: {
          receiptId: action.payload.receiptId,
          loading: true,
          items: [],
        },
      };

    case types.GET_RECEIPT_LOG_COMPLETED:
      return {
        ...state,
        logs: {
          ...state.logs,
          loading: false,
          items: action.payload.items,
        },
      };

    case types.GET_RECEIPT_LOG_FAILED:
      return {
        ...state,
        logs: {
          receiptId: null,
          loading: false,
          items: [],
        },
      };
  }

  return state;
};
