import { all, fork } from "redux-saga/effects";
import { watchAccountRequestStart } from "./Account/AccountSagas";
import { watchAuthStart } from "./Auth/AuthSagas";
import { watchDeviceRequestStart } from "./Device/DeviceSagas";
import { watchDeviceSettingRequestStart } from "./DeviceSetting/DeviceSettingSagas";
import { watchReceiptRequestStart } from "./Receipt/ReceiptSagas";
import { watchUserRequestStart } from "./User/UserSagas";

export const rootSaga = function* root() {
    yield all([
        fork(watchAuthStart),
        fork(watchDeviceRequestStart),
        fork(watchDeviceSettingRequestStart),
        fork(watchUserRequestStart),
        fork(watchReceiptRequestStart),
        fork(watchAccountRequestStart),
    ]);
};
