import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import {
  createStyles,
  lighten,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { default as React, useEffect } from "react";
import LogicStatus from "../../components/logic-status";
import TableSortHeader from "../../components/table-sort-header";
import UserStatus from "../../components/user-status";
import { UserCreateContainer } from "../../forms/user-create";
import { FormContainer as UserUpdateForm } from "../../forms/user-update";
import { Pagination } from "../../store/Common/CommonModel";
import { Settings } from "../../store/Settings/SettingsModel";
import { User, UserFilter } from "../../store/User/UserModel";
interface Props {
  usersLoading: boolean;
  users: User[];
  settings: Settings;
  pagination: Pagination;
  filter: UserFilter;
  onRequestUserList: (filter: UserFilter) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      // minWidth: 700,
    },
    progress: {
      textAlign: "center",
    },
    buttonView: {
      margin: theme.spacing(1),
      marginRight: 0,
    },
    headCell: {
      paddingTop: 25,
      verticalAlign: "top",
    },
    filterCell: {
      paddingTop: 6,
      verticalAlign: "top",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    error: {
      backgroundColor: lighten(theme.palette.error.light, 0.75),
    },
    event: {
      backgroundColor: lighten(theme.palette.info.light, 0.9),
    },
    command: {
      backgroundColor: lighten(theme.palette.warning.light, 0.9),
    },
    buttonCreate: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  })
);

export const PageComponent: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const { usersLoading, users, filter, pagination, onRequestUserList } = props;

  useEffect(() => {
    onRequestUserList(filter);
  }, []);

  // ***

  const [anchorEl, setAnchorEl] =
    React.useState<null | HTMLButtonElement>(null);
  const [openActions, setOpenActions] = React.useState<null | Number>(null);

  const handleActionClick = (
    id: Number,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
    setOpenActions(id);
  };

  const handleActionClose = () => {
    setOpenActions(null);
    setAnchorEl(null);
  };

  // Просмотр пользователя

  const [openDetails, setOpenDetails] = React.useState(false);
  const [detailed, setDetailed] = React.useState<User>();

  const handleOpenDetails = (item: User) => () => {
    setDetailed(item);
    setOpenDetails(true);
  };

  const handleCloseDetails = () => {
    setOpenDetails(false);
  };

  // Создание пользователя

  const [openCreate, setOpenCreate] = React.useState<boolean>(false);

  const handleOpenCreate = () => {
    setOpenCreate(true);
  };

  const handleCloseCreate = () => {
    setOpenCreate(false);
  };

  // Редактирование пользователя

  const [openUpdate, setOpenUpdate] = React.useState<boolean>(false);
  const [updatingItem, setUpdatingItem] = React.useState<User>();

  const handleOpenUpdate = (item: User) => () => {
    setOpenUpdate(true);
    setUpdatingItem(item);
  };

  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };

  // ***

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    filter.page = newPage;
    onRequestUserList(filter);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    filter.page = 0;
    filter.per_page = parseInt(event.target.value, 10);
    onRequestUserList(filter);
  };

  const handleChangeOrder =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      if (filter.order_by !== property) {
        filter.order = "asc";
        filter.order_by = property;
      } else {
        filter.order = filter.order === "asc" ? "desc" : "asc";
      }

      onRequestUserList(filter);
    };

  // ***

  const getRowStyle = (item: User) => {
    switch (item.status) {
      case 0:
        return classes.event;

      case 1:
        return classes.command;

      default:
        return null;
    }
  };

  return (
    <>
      <h1>Пользователи</h1>

      <TableContainer>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" className={classes.headCell}>
                <TableSortHeader
                  id="id"
                  title="ID"
                  order={filter.order}
                  orderBy={filter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="left" className={classes.headCell}>
                <TableSortHeader
                  id="first_name"
                  title="Имя"
                  order={filter.order}
                  orderBy={filter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="left" className={classes.headCell}>
                <TableSortHeader
                  id="last_name"
                  title="Фамилия"
                  order={filter.order}
                  orderBy={filter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="left" className={classes.headCell}>
                <TableSortHeader
                  id="login"
                  title="Логин"
                  order={filter.order}
                  orderBy={filter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="center" className={classes.headCell}>
                <TableSortHeader
                  id="is_admin"
                  title="Админ?"
                  order={filter.order}
                  orderBy={filter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="center" className={classes.headCell}>
                <TableSortHeader
                  id="status"
                  title="Статус"
                  order={filter.order}
                  orderBy={filter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="right" className={classes.headCell}>
                Действия
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {users &&
              0 < users.length &&
              users.map((item) => (
                <TableRow className={getRowStyle(item)} hover key={item.id}>
                  <TableCell align="center">{item.id}</TableCell>
                  <TableCell align="left">{item.first_name}</TableCell>
                  <TableCell align="left">{item.last_name}</TableCell>
                  <TableCell align="left">{item.login}</TableCell>
                  <TableCell align="center">
                    <LogicStatus value={item.is_admin} />
                  </TableCell>
                  <TableCell align="center">
                    <UserStatus value={item.status} />
                  </TableCell>
                  <TableCell align="right">
                    <Hidden only={["xs", "sm", "md"]}>
                      <IconButton
                        color="primary"
                        onClick={handleOpenDetails(item)}
                      >
                        <Tooltip title="Детали">
                          <VisibilityIcon />
                        </Tooltip>
                      </IconButton>

                      <IconButton onClick={handleOpenUpdate(item)}>
                        <Tooltip title="Редактировать">
                          <EditIcon />
                        </Tooltip>
                      </IconButton>
                    </Hidden>

                    <Hidden only={["lg", "xl"]}>
                      <Popper
                        id={"actions-" + item.id}
                        open={openActions === item.id}
                        anchorEl={anchorEl}
                        placement="bottom"
                      >
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={true}
                          onClick={handleActionClose}
                          onClose={handleActionClose}
                        >
                          <MenuItem
                            color="primary"
                            onClick={handleOpenDetails(item)}
                          >
                            Детали
                          </MenuItem>

                          <MenuItem onClick={handleOpenUpdate(item)}>
                            Редактировать
                          </MenuItem>

                          {/* {!item.is_blocked && (
                            <MenuItem onClick={handleBlock(item)}>
                              Заблокировать
                            </MenuItem>
                          )}
                          {item.is_blocked && (
                            <MenuItem onClick={handleUnblock(item)}>
                              Разблокировать
                            </MenuItem>
                          )} */}
                        </Menu>
                      </Popper>

                      <IconButton
                        id={"show-actions-" + item.id}
                        aria-haspopup="true"
                        onClick={(e) => handleActionClick(item.id, e)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Hidden>
                  </TableCell>
                </TableRow>
              ))}

            {(!users || users.length === 0) && (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  Записей нет
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {(1 < pagination.page_count || 10 < pagination.per_page) && (
        <TablePagination
          component="div"
          count={pagination.total_count}
          page={pagination.page}
          onChangePage={handleChangePage}
          rowsPerPage={pagination.per_page}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="Per page:"
        />
      )}

      <Dialog
        scroll="paper"
        onClose={handleCloseDetails}
        aria-labelledby="customized-dialog-title"
        open={openDetails}
        maxWidth="md"
        fullWidth={true}
      >
        {detailed && (
          <>
            <DialogTitle id="customized-dialog-title">
              Клиент #<b>{detailed.id}</b>
            </DialogTitle>
            <DialogContent dividers>
              <ul>
                <li>
                  <b>id:</b> {detailed.id}
                </li>
                <li>
                  <b>public_id:</b> {detailed.public_id}
                </li>
                <li>
                  <b>first_name:</b> {detailed.first_name}
                </li>
                <li>
                  <b>last_name:</b> {detailed.last_name}
                </li>
                <li>
                  <b>login:</b> {detailed.login}
                </li>
                <li>
                  <b>email:</b> {detailed.email}
                </li>
                <li>
                  <b>phone:</b> {detailed.phone}
                </li>
                <li>
                  <b>status:</b> {detailed.status}
                </li>
                <li>
                  <b>is_admin:</b> {detailed.is_admin}
                </li>
                <li>
                  <b>create_datetime:</b> {detailed.create_datetime}
                </li>
              </ul>
            </DialogContent>
          </>
        )}
        <DialogActions>
          <Button onClick={handleCloseDetails} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        scroll="paper"
        onClose={handleCloseCreate}
        aria-labelledby="customized-dialog-title"
        open={openCreate}
        maxWidth="md"
        fullWidth={true}
      >
        <UserCreateContainer handleClose={handleCloseCreate} />
      </Dialog>

      <Dialog
        scroll="paper"
        onClose={handleCloseCreate}
        aria-labelledby="customized-dialog-title"
        open={openUpdate}
        maxWidth="md"
        fullWidth={true}
      >
        <UserUpdateForm user={updatingItem} handleClose={handleCloseUpdate} />
      </Dialog>

      <Backdrop className={classes.backdrop} open={usersLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Fab
        color="primary"
        aria-label="add"
        className={classes.buttonCreate}
        onClick={handleOpenCreate}
      >
        <AddIcon />
      </Fab>
    </>
  );
};
