import {
  Dialog, Fab,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import {
  createStyles,
  lighten,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";

import EditIcon from "@material-ui/icons/Edit";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { default as React, useEffect } from "react";
import TableSortHeader from "../../components/table-sort-header";
import { DeviceSettingUpdateContainer as DeviceSettingUpdateForm } from "../../forms/device-setting-update";
import { DeviceSettingCreateContainer as DeviceSettingCreateForm } from "../../forms/device-setting-create";
import {Directory, Pagination} from "../../store/Common/CommonModel";
import { DeviceSetting, DeviceSettingFilter } from "../../store/DeviceSetting/DeviceSettingModel";
import { Settings } from "../../store/Settings/SettingsModel";
import AddIcon from "@material-ui/icons/Add";
import {NumberFieldFilter} from "../../components/filters/number-field-filter";
import {UserFilter} from "../../components/filters/user-filter";
import {DeviceSettingFilterComp} from "../../components/filters/device-setting-filter";
import useFilters from "../../hooks/useFilters";

interface Props {
  onRequestDeviceSettingList: (filter: DeviceSettingFilter) => void;
  authIsAdmin: boolean;
  devicesSettingsLoading: boolean;
  devicesSettings: DeviceSetting[];
  pagination: Pagination;
  filter: DeviceSettingFilter;
  settings: Settings;
  userDirectory: Directory[];
  deviceSettingDirectory: Directory[];
  onRequestDeviceSettingDirectory:()=> void;
  onRequestUserDirectory:()=> void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    blockedSwitch: {
      float: "right",
    },
    table: {
      minWidth: 700,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    buttonView: {
      margin: theme.spacing(1),
      marginRight: 0,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    lh24px: {
      lineHeight: "24px",
    },
    blocked: {
      backgroundColor: lighten(theme.palette.error.light, 0.75),
    },
    buttonCreate: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    headCell: {
      paddingTop: 5,
      verticalAlign: "top",
      fontWeight: "bold",
    },
    filterCell: {
      paddingTop: 0,
      verticalAlign: "top",
    },
  })
);

export const PageComponent: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const {
    authIsAdmin,
    devicesSettings,
    filter,
    pagination,
    onRequestDeviceSettingList,
    userDirectory,
    onRequestUserDirectory,
    deviceSettingDirectory,
    onRequestDeviceSettingDirectory,
  } = props;

  useEffect(() => {
    onRequestDeviceSettingList(filter);
    onRequestDeviceSettingDirectory();
    if (authIsAdmin) onRequestUserDirectory();
  }, []);

  const [newFilter, handleChangeOrder, handleChangeValue, handleChangeNumberField] = useFilters(
      filter,
      onRequestDeviceSettingList
  );

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    filter.page = newPage;
    onRequestDeviceSettingList(filter);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    filter.page = 0;
    filter.per_page = parseInt(event.target.value, 10);
    onRequestDeviceSettingList(filter);
  };

  // Создание настройки

  const [openCreate, setOpenCreate] = React.useState<boolean>(false);

  const handleOpenCreate = () => {
    setOpenCreate(true);
  };

  const handleCloseCreate = () => {
    setOpenCreate(false);
  };

  // Редактирование

  const [openUpdate, setOpenUpdate] = React.useState<boolean>(false);
  const [updating, setUpdating] = React.useState<DeviceSetting>();

  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };

  const handleOpenUpdate = (item: DeviceSetting) => () => {
    setOpenUpdate(true);
    setUpdating(item);
  };

  /* * */

  const [anchorEl, setAnchorEl] =
    React.useState<null | HTMLButtonElement>(null);
  const [openActions, setOpenActions] = React.useState<null | Number>(null);

  const handleActionClick = (
    id: Number,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
    setOpenActions(id);
  };

  const handleActionClose = () => {
    setOpenActions(null);
    setAnchorEl(null);
  };

  const getRowStyle = (item: DeviceSetting) => {
    return null;
  };


  const handleChangeUserIdField = (id: number) => {
    filter.user_id = id;
    handleChangeValue({
      page: 0,
      user_id: id,
    })
  };

  const handleChangeKeyField = (id: string) => {
    filter.key = id;
    handleChangeValue({
      page: 0,
      key: id,
    })
  };

  return (
    <>
      <h1>
        Настройки оборудования
      </h1>

      <TableContainer>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.filterCell}  width = "5%"> </TableCell>
              <TableCell className={classes.filterCell} width = "10%">
                {authIsAdmin && (
                <>
                  <UserFilter
                      value={filter.user_id}
                      variants={userDirectory}
                      onChange={handleChangeUserIdField}
                  >
                  </UserFilter>
                </>
                )}
              </TableCell>
              <TableCell className={classes.filterCell} width = "10%">
                  <NumberFieldFilter
                      value={filter.controller}
                      onChange={handleChangeNumberField}
                      idFilter={"controller"}
                      filter={filter}
                  />
              </TableCell>
              <TableCell className={classes.filterCell} width = "5%"></TableCell>
              <TableCell className={classes.filterCell} width = "25%">
                <DeviceSettingFilterComp
                  value={filter.key}
                  variants={deviceSettingDirectory}
                  onChange={handleChangeKeyField}
              ></DeviceSettingFilterComp>
              </TableCell>
              <TableCell className={classes.filterCell} width = "5%"> </TableCell>
              <TableCell className={classes.filterCell} width = "5%"> </TableCell>
              <TableCell className={classes.filterCell} width = "5%"> </TableCell>
              <TableCell className={classes.filterCell} width = "5%"> </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">
                <TableSortHeader
                  id="id"
                  title="ID"
                  order={filter.order}
                  orderBy={filter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="center">
                <TableSortHeader
                    id="user_id"
                    title="Клиент"
                    order={filter.order}
                    orderBy={filter.order_by}
                    onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="center">
                <TableSortHeader
                  id="controller"
                  title="ID (axiot)"
                  order={filter.order}
                  orderBy={filter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="center">
                <TableSortHeader
                    id="device_id"
                    title="Device ID"
                    order={filter.order}
                    orderBy={filter.order_by}
                    onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="center">
                <TableSortHeader
                    id="key"
                    title="Ключ"
                    order={filter.order}
                    orderBy={filter.order_by}
                    onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="center">
                <TableSortHeader
                    id="value"
                    title="Значение"
                    order={filter.order}
                    orderBy={filter.order_by}
                    onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="center">
                <TableSortHeader
                    id="create_datetime"
                    title="Дата создания"
                    order={filter.order}
                    orderBy={filter.order_by}
                    onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="center">
                <TableSortHeader
                    id="update_datetime"
                    title="Редактировано"
                    order={filter.order}
                    orderBy={filter.order_by}
                    onChange={handleChangeOrder}
                />
              </TableCell>

              <TableCell align="right" width="180">
                Действия
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {devicesSettings &&
              0 < devicesSettings.length &&
            devicesSettings.map((item) => (
                <TableRow className={getRowStyle(item)} hover key={item.id}>
                  <TableCell align="left">{item.id}</TableCell>
                  <TableCell align="center">{item.user_title}</TableCell>
                  <TableCell align="center">{item.controller}</TableCell>
                  <TableCell align="center">{item.device_id}</TableCell>
                  <TableCell component="th" scope="row">
                    {item.key_title}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.value}
                  </TableCell>
                  <TableCell align="center">{item.create_datetime}</TableCell>
                  <TableCell align="center">{item.update_datetime}</TableCell>

                  <TableCell align="right">
                    <Hidden only={["xs", "sm", "md"]}>
                      <IconButton onClick={handleOpenUpdate(item)}>
                        <Tooltip title="Редактировать">
                          <EditIcon />
                        </Tooltip>
                      </IconButton>


                    </Hidden>

                    <Hidden only={["lg", "xl"]}>
                      <Popper
                        id={"actions-" + item.id}
                        open={openActions === item.id}
                        anchorEl={anchorEl}
                        placement="bottom"
                      >
                        <Menu
                          id={"actions-device-setting-" + item.id}
                          anchorEl={anchorEl}
                          keepMounted
                          open={true}
                          onClick={handleActionClose}
                          onClose={handleActionClose}
                        >
                          <MenuItem
                            color="primary"
                            onClick={handleOpenUpdate(item)}
                          >
                            Редактировать
                          </MenuItem>


                        </Menu>
                      </Popper>

                      <IconButton
                        id={"show-actions-" + item.id}
                        aria-haspopup="true"
                        onClick={(e) => handleActionClick(item.id, e)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Hidden>
                  </TableCell>
                </TableRow>
              ))}

            {(!devicesSettings || devicesSettings.length === 0) && (
              <>
                <Hidden only={["xs", "sm", "md"]}>
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      Записей нет
                    </TableCell>
                  </TableRow>
                </Hidden>

                <Hidden only={["lg", "xl"]}>
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      Записей нет
                    </TableCell>
                  </TableRow>
                </Hidden>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {(1 < pagination.page_count || 10 < pagination.per_page) && (
        <TablePagination
          component="div"
          count={pagination.total_count}
          page={pagination.page}
          onChangePage={handleChangePage}
          rowsPerPage={pagination.per_page}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="Per page:"
        />
      )}

      <Dialog
        scroll="paper"
        onClose={handleCloseUpdate}
        aria-labelledby="customized-dialog-title"
        open={openUpdate}
        maxWidth="md"
        fullWidth={true}
      >
        <DeviceSettingUpdateForm deviceSetting={updating} handleClose={handleCloseUpdate} />
      </Dialog>

      <Dialog
          scroll="paper"
          onClose={handleCloseCreate}
          aria-labelledby="customized-dialog-title"
          open={openCreate}
          maxWidth="md"
          fullWidth={true}
      >
        <DeviceSettingCreateForm handleClose={handleCloseCreate} />
      </Dialog>

      <Fab
          color="primary"
          aria-label="add"
          className={classes.buttonCreate}
          onClick={handleOpenCreate}
      >
        <AddIcon />
      </Fab>

    </>
  );
};
