import agentInstance from "../../api/agent";
import { Directory } from "../Common/CommonModel";
import { Account } from "./AccountModel";

export const getAccountDirectory = (
  prjId: Number,
  params: {}
): Promise<Directory[]> => {
  return new Promise<Directory[]>((resolve) => {
    resolve(agentInstance(prjId).get(`accounts/dir`));
  });
};

export const getTaxationDirectory = (
    prjId: Number,
    params: {}
): Promise<Directory[]> => {
  return new Promise<Directory[]>((resolve) => {
    resolve(agentInstance(prjId).get(`taxation/dir`));
  });
};

export const getNdsDirectory = (
    prjId: Number,
    params: {}
): Promise<Directory[]> => {
  return new Promise<Directory[]>((resolve) => {
    resolve(agentInstance(prjId).get(`nds/dir`));
  });
};

export const getProviderDirectory = (
    prjId: Number,
    params: {}
): Promise<Directory[]> => {
  return new Promise<Directory[]>((resolve) => {
    resolve(agentInstance(prjId).get(`provider/dir`));
  });
};

export const getAccountsList = (
  prjId: Number,
  params: {}
): Promise<Account[]> => {
  return new Promise<Account[]>((resolve) => {
    resolve(
      agentInstance(prjId).get(`accounts`, {
        params: params["filter"],
      })
    );
  });
};

export const postAccountCreate = (prjId: Number, params: {}): Promise<any> => {
  return new Promise<Account[]>((resolve) => {
    resolve(agentInstance(prjId).post(`accounts`, params["form"]));
  });
};

export const postAccountUpdate = (prjId: Number, params: {}): Promise<any> => {
  return new Promise<Account[]>((resolve) => {
    resolve(
      agentInstance(prjId).post(`accounts/` + params["id"], params["form"])
    );
  });
};

export const deleteAccount = (prjId: Number, params: {}): Promise<any> => {
  return new Promise<Account[]>((resolve) => {
    resolve(agentInstance(prjId).delete(`accounts/` + params["id"]));
  });
};
