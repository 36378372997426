export const GET_RECEIPT_LIST_START = "GET_RECEIPT_LIST - Start";
export const GET_RECEIPT_LIST_COMPLETED = "GET_RECEIPT_LIST - Completed";
export const GET_RECEIPT_LIST_FAILED = "GET_RECEIPT_LIST - Failed";

export const GET_RECEIPT_LOG_START = "GET_RECEIPT_LOG - Start";
export const GET_RECEIPT_LOG_COMPLETED = "GET_RECEIPT_LOG - Completed";
export const GET_RECEIPT_LOG_FAILED = "GET_RECEIPT_LOG - Failed";

export const GET_PUBLIC_RECEIPT_ITEM_START = "GET_PUBLIC_RECEIPT_ITEM - Start";
export const GET_PUBLIC_RECEIPT_ITEM_COMPLETED = "GET_PUBLIC_RECEIPT_ITEM - Completed";
export const GET_PUBLIC_RECEIPT_ITEM_FAILED = "GET_PUBLIC_RECEIPT_ITEM - Failed";
