import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { AccountFilter } from "../../components/filters/account-filter";
import { UserFilter } from "../../components/filters/user-filter";
import { Directory } from "../../store/Common/CommonModel";
import { DeviceUpdateForm } from "../../store/Device/DeviceModel";

interface Props {
  isAdmin: boolean;
  deviceUpdating: boolean;
  userDirectory: Directory[];
  accountDirectory: Directory[];
  onRequestDeviceUpdate: (form: DeviceUpdateForm) => void;
  onRequestAccountDirectory: () => void;
  onRequestUserDirectory: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      marginLeft: 0,
      marginRight: 0,
      width: "100%",
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
  })
);

export const FormComponent = (props: any) => {
  const classes = useStyles();
  const {
    isAdmin,
    device,
    handleClose,
    accountCreating,
    onRequestDeviceUpdate,
    onRequestAccountDirectory,
    onRequestUserDirectory,
    userDirectory,
    accountDirectory,
  } = props;

  const updateForm = {
    account_id: device.account_id,
    comment: device.comment,
    user_id: device.user_id,
    controller: device.controller,
    project_title: device.project_title,
    id: device.id,
  };

  const [fields, setFields] = React.useState<DeviceUpdateForm>(updateForm);

  const handleChangeField =
    (prop: keyof DeviceUpdateForm) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFields({ ...fields, [prop]: event.target.value });
    };

  const handleChangeUser = (value: any) => {
    setFields({ ...fields, user_id: value });
  };

  const handleChangeAccount = (value: any) => {
    setFields({ ...fields, account_id: value });
  };

  // ***

  const handleStartCreate = () => {
    onRequestDeviceUpdate(device.id, fields);
    handleClose();
  };

  useEffect(() => {
    onRequestAccountDirectory();
    if (isAdmin) {
      onRequestUserDirectory();
    }
  }, []);

  return (
    <>
      <DialogTitle>Редактирование оборудования #<b>{fields.id} ({fields.project_title})</b></DialogTitle>
      <DialogContent dividers>
        {isAdmin && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <UserFilter
                  value={fields.user_id}
                  variants={userDirectory}
                  onChange={handleChangeUser}
                ></UserFilter>
              </FormControl>
            </Grid>
          </Grid>
        )}

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <AccountFilter
                value={fields.account_id}
                variants={accountDirectory}
                userValue={fields.user_id}
                users={userDirectory}
                onChange={handleChangeAccount}
              ></AccountFilter>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <TextField
                multiline
                rows={5}
                value={fields.comment}
                label="Комментарий"
                onChange={handleChangeField("comment")}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <TextField
                value={fields.controller}
                label="№ контроллера"
              />
            </FormControl>
          </Grid>
        </Grid>

      </DialogContent>
      <DialogActions>
        {!accountCreating && (
          <>
            <Button onClick={handleClose} color="primary">
              Отмена
            </Button>
            <Button
              onClick={handleStartCreate}
              variant="contained"
              color="primary"
            >
              Сохранить
            </Button>
          </>
        )}

        {accountCreating && <CircularProgress />}
      </DialogActions>
    </>
  );
};
