import { connect } from "react-redux";
import {
  receiptLogsRequestStartAction,
  receiptsRequestStartAction,
} from "../../store/Receipt/ReceiptAction";
import { ReceiptFilter } from "../../store/Receipt/ReceiptModel";
import { State } from "../../store/reducers";
import { PageComponent } from "./page.component";

const mapStateToProps = (state: State) => ({
  receiptsLoading: state.receipt.list.loading,
  receipts: state.receipt.list.items,
  pagination: state.receipt.list.pagination,
  filter: state.receipt.filter,
  settings: state.settings,
});

const mapDispatchToProps = (dispatch: any) => ({
  onRequestReceiptList: (filter: ReceiptFilter) =>
    dispatch(receiptsRequestStartAction(filter)),
  onRequestReceiptLogs: (id: number) =>
    dispatch(receiptLogsRequestStartAction(id)),
});

export const PageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PageComponent);
