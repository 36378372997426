import { Badge } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import {
  RECEIPT_STATUS_ERROR,
  RECEIPT_STATUS_NEW,
  RECEIPT_STATUS_FORCE_NEW,
  RECEIPT_STATUS_PENDING,
  RECEIPT_STATUS_SCIPPED,
  RECEIPT_STATUS_SUCCESS,
  RECEIPT_STATUS_PENDING_SEND,
  RECEIPT_STATUS_WARNING,
  RECEIPT_STATUS_RETURN
} from "../store/Receipt/ReceiptConstant";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    active: {
      background: "#00a65a",
      color: "#FFF",
      padding: "4px 10px",
    },
    inactive: {
      background: "#dd4b39",
      color: "#FFF",
      padding: "4px 10px",
    },
  })
);

const ReceiptStatus = (props: any) => {
  // const classes = useStyles();
  const { value } = props;

  if (value === RECEIPT_STATUS_NEW) {
    return <Badge badgeContent="Новый" color="primary"></Badge>;
  }
  if (value === RECEIPT_STATUS_FORCE_NEW) {
    return <Badge badgeContent="Отмодерирован" color="primary"></Badge>;
  }

  if (value === RECEIPT_STATUS_PENDING) {
    return <Badge badgeContent="Взято&nbsp;в&nbsp;работу" color="primary"></Badge>;
  }

  if (value === RECEIPT_STATUS_PENDING_SEND) {
    return <Badge badgeContent="В&nbsp;работе&nbsp;(отправлено)" color="primary"></Badge>;
  }

  if (value === RECEIPT_STATUS_SUCCESS) {
    return <Badge badgeContent="Фискализирован" color="primary"></Badge>;
  }

  if (value === RECEIPT_STATUS_ERROR) {
    return <Badge badgeContent="Ошибка" color="error"></Badge>;
  }

  if (value === RECEIPT_STATUS_SCIPPED) {
    return <Badge badgeContent="Пропущен" color="error"></Badge>;
  }

  if (value === RECEIPT_STATUS_WARNING) {
    return <Badge badgeContent="На&nbsp;модерации" color="error"></Badge>;
  }

  if (value === RECEIPT_STATUS_RETURN) {
    return <Badge badgeContent="Возврат" color="error"></Badge>;
  }


  return <>&nbsp;</>;
};

export default ReceiptStatus;
