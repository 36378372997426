import agentInstance from "../../api/agent";
import { Directory } from "../Common/CommonModel";
import { DeviceSetting } from "./DeviceSettingModel";

export const getDeviceSettingDirectory = (
  prjId: Number,
  params: {}
): Promise<Directory[]> => {
  return new Promise<Directory[]>((resolve) => {
    resolve(agentInstance(prjId).get(`settings/dir`));
  });
};

export const getDevicesSettingsList = (
  prjId: Number,
  params: {}
): Promise<DeviceSetting[]> => {
  return new Promise<DeviceSetting[]>((resolve) => {
    resolve(
      agentInstance(prjId).get(`settings`, {
        params: params["filter"],
      })
    );
  });
};

export const postDeviceSettingUpdate = (prjId: Number, params: {}): Promise<any> => {
  return new Promise<DeviceSetting[]>((resolve) => {
    resolve(
      agentInstance(prjId).post(`settings/` + params["id"], params["form"])
    );
  });
};

export const postDeviceSettingCreate = (prjId: Number, params: {}): Promise<any> => {
  return new Promise<DeviceSetting[]>((resolve) => {
    resolve(agentInstance(prjId).post(`settings`, params["form"]));
  });
};

