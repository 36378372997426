import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import {
  createStyles,
  lighten,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useConfirm } from "material-ui-confirm";
import { default as React, useEffect } from "react";
import { TextFieldFilter } from "../../components/filters/text-field-filter";
import TableSortHeader from "../../components/table-sort-header";
import { FormContainer as AccountCreateForm } from "../../forms/account-create";
import { FormContainer as AccountUpdateForm } from "../../forms/account-update";
import useFilters from "../../hooks/useFilters";
import { Account, AccountFilter } from "../../store/Account/AccountModel";
import { Pagination } from "../../store/Common/CommonModel";
import * as statuses from "../../store/Receipt/ReceiptConstant";
import { Settings } from "../../store/Settings/SettingsModel";

interface Props {
  accountsLoading: boolean;
  accounts: Account[];
  settings: Settings;
  pagination: Pagination;
  filter: AccountFilter;
  onRequestAccountList: (filter: AccountFilter) => void;
  onRequestAccountDelete: (id: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      // minWidth: 700,
    },
    progress: {
      textAlign: "center",
    },
    buttonView: {
      margin: theme.spacing(1),
      marginRight: 0,
    },
    headCell: {
      paddingTop: 5,
      verticalAlign: "top",
      fontWeight: "bold",
    },
    filterCell: {
      paddingTop: 6,
      verticalAlign: "top",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    statusDefault: {},
    statusNew: {
      backgroundColor: lighten(theme.palette.error.light, 0.75),
    },
    statusPending: {
      backgroundColor: lighten(theme.palette.error.light, 0.75),
    },
    statusError: {
      backgroundColor: lighten(theme.palette.error.light, 0.75),
    },
    statusSuccess: {
      backgroundColor: lighten(theme.palette.error.light, 0.75),
    },
    buttonCreate: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  })
);

export const PageComponent: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const confirm = useConfirm();



  const {
    accountsLoading,
    accounts,
    filter,
    pagination,
    settings,
    onRequestAccountList,
    onRequestAccountDelete,
  } = props;

  useEffect(() => {
    onRequestAccountList(filter);
  }, []);

  // ***
  const [newFilter, handleChangeOrder, handleChangeValue] = useFilters(
    filter,
    onRequestAccountList
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLButtonElement>(
    null
  );
  const [openActions, setOpenActions] = React.useState<null | Number>(null);

  const handleActionClick = (
    id: Number,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
    setOpenActions(id);
  };

  const handleActionClose = () => {
    setOpenActions(null);
    setAnchorEl(null);
  };

  // ***

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    filter.page = newPage;
    onRequestAccountList(filter);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    filter.page = 0;
    filter.per_page = parseInt(event.target.value, 10);
    onRequestAccountList(filter);
  };

  // ***

  const getRowStyle = (item: Account) => {
    switch (item.status) {
      case statuses.RECEIPT_STATUS_NEW:
        return classes.statusNew;

      case statuses.RECEIPT_STATUS_PENDING:
        return classes.statusPending;

      case statuses.RECEIPT_STATUS_SUCCESS:
        return classes.statusSuccess;

      case statuses.RECEIPT_STATUS_ERROR:
        return classes.statusError;

      default:
        return classes.statusDefault;
    }
  };

  // Просмотр реквизитов

  const [openDetails, setOpenDetails] = React.useState(false);
  const [detailed, setDetailed] = React.useState<Account>();

  const handleOpenDetails = (item: Account) => () => {
    setDetailed(item);
    setOpenDetails(true);
  };

  const handleCloseDetails = () => {
    setOpenDetails(false);
  };

  // Создание реквизитов

  const [openCreate, setOpenCreate] = React.useState<boolean>(false);

  const handleOpenCreate = () => {
    setOpenCreate(true);
  };

  const handleCloseCreate = () => {
    setOpenCreate(false);
  };

  // Редактирование реквизитов

  const [openUpdate, setOpenUpdate] = React.useState<boolean>(false);
  const [updatingItem, setUpdatingItem] = React.useState<Account>();

  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };

  const handleOpenUpdate = (item: Account) => () => {
    setOpenUpdate(true);
    setUpdatingItem(item);
  };

  // ***

  const handleDelete = (item: Account) => () => {
    confirm({
      title: "Вы точно хотите удалить реквизиты?",
      //   description: "!!!",
    }).then(() => {
      onRequestAccountDelete(item.id);
    });
  };

  const handleChangeTextField = (value: string, idFilter: string) => {
    if (idFilter == "company") {
      filter.company = value;
      handleChangeValue({
        page: 0,
        company: value,
      })
    }
    else if(idFilter == "inn") {
      filter.inn = value;
      handleChangeValue({
        page: 0,
        inn: value,
      })
    }
  };

  return (
    <>
      <h1>Реквизиты</h1>

      <TableContainer>
        <Table className={classes.table} size="small">
          <TableHead>
          <TableRow >
              <TableCell align="center" className={classes.headCell}>
              </TableCell>
              <TableCell align="left" className={classes.headCell}>
                <TextFieldFilter
                  company={filter.company}
                  onChange={handleChangeTextField}
                  idFilter={"company"}
                />
              </TableCell>
              <TableCell align="left" className={classes.headCell}>
              </TableCell>
              <TableCell align="center" className={classes.headCell}>
              <TextFieldFilter
                  company={filter.inn}
                  onChange={handleChangeTextField}
                  idFilter={"inn"}
                />
              </TableCell>
              <TableCell align="right" className={classes.headCell}>
              </TableCell>
            </TableRow>
  
            <TableRow>
              <TableCell align="center" className={classes.headCell}>
                <TableSortHeader
                  id="id"
                  title="№"
                  order={filter.order}
                  orderBy={filter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="left" className={classes.headCell}>
                <TableSortHeader
                  id="company"
                  title="Название компании"
                  order={filter.order}
                  orderBy={filter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="left" className={classes.headCell}>
                <TableSortHeader
                  id="service"
                  title="Название услуги"
                  order={filter.order}
                  orderBy={filter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="center" className={classes.headCell}>
                <TableSortHeader
                  id="inn"
                  title="ИНН"
                  order={filter.order}
                  orderBy={filter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="right" className={classes.headCell}>
                Действия
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {accounts &&
              0 < accounts.length &&
              accounts.map((item) => (
                <TableRow className={getRowStyle(item)} hover key={item.id}>
                  <TableCell align="center">{item.id}</TableCell>
                  <TableCell align="left">{item.company}</TableCell>
                  <TableCell align="left">{item.service}</TableCell>
                  <TableCell align="center">{item.inn}</TableCell>
                  <TableCell align="right">
                    <Hidden only={["xs", "sm", "md"]}>
                      <IconButton
                        color="primary"
                        onClick={handleOpenDetails(item)}
                      >
                        <Tooltip title="Детали">
                          <VisibilityIcon />
                        </Tooltip>
                      </IconButton>

                      <IconButton onClick={handleOpenUpdate(item)}>
                        <Tooltip title="Редактировать">
                          <EditIcon />
                        </Tooltip>
                      </IconButton>

                      <IconButton onClick={handleDelete(item)}>
                        <Tooltip title="Удалить">
                          <DeleteIcon />
                        </Tooltip>
                      </IconButton>
                    </Hidden>

                    <Hidden only={["lg", "xl"]}>
                      <Popper
                        id={"actions-" + item.id}
                        open={openActions === item.id}
                        anchorEl={anchorEl}
                        placement="bottom"
                      >
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={true}
                          onClick={handleActionClose}
                          onClose={handleActionClose}
                        >
                          <MenuItem
                            color="primary"
                            onClick={handleOpenDetails(item)}
                          >
                            Детали
                          </MenuItem>

                          <MenuItem onClick={handleOpenUpdate(item)}>
                            Редактировать
                          </MenuItem>

                          <MenuItem onClick={handleDelete(item)}>
                            Удалить
                          </MenuItem>
                        </Menu>
                      </Popper>

                      <IconButton
                        id={"show-actions-" + item.id}
                        aria-haspopup="true"
                        onClick={(e) => handleActionClick(item.id, e)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Hidden>
                  </TableCell>
                </TableRow>
              ))}

            {(!accounts || accounts.length === 0) && (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  Записей нет
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {(1 < pagination.page_count || 10 < pagination.per_page) && (
        <TablePagination
          component="div"
          count={pagination.total_count}
          page={pagination.page}
          onChangePage={handleChangePage}
          rowsPerPage={pagination.per_page}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="Per page:"
        />
      )}

      <Dialog
        scroll="paper"
        onClose={handleCloseDetails}
        aria-labelledby="customized-dialog-title"
        open={openDetails}
        maxWidth="md"
        fullWidth={true}
      >
        {detailed && (
          <>
            <DialogTitle id="customized-dialog-title">
              Реквизиты #<b>{detailed.id}</b>
            </DialogTitle>
            <DialogContent dividers>
              <ul>
                <li>
                  <b>№: </b> {detailed.id}
                </li>
                <li>
                  <b>Название компании: </b> {detailed.company}
                </li>
                <li>
                  <b>Link API регистрации чеков: </b> {detailed.link}
                </li>
                <li>
                  <b>Название услуги: </b> {detailed.service}
                </li>
                <li>
                  <b>Token: </b> {detailed.token}
                </li>
                <li>
                  <b>Пользователь: </b> {detailed.user_id}
                </li>
                <li>
                  <b>Статус реквизитов:</b> {detailed.status}
                </li>
                <li>
                  <b>Дата создания реквизитов:</b> {detailed.create_datetime}
                </li>
              </ul>
            </DialogContent>
          </>
        )}
        <DialogActions>
          <Button onClick={handleCloseDetails} color="primary">
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        scroll="paper"
        onClose={handleCloseCreate}
        aria-labelledby="customized-dialog-title"
        open={openCreate}
        maxWidth="md"
        fullWidth={true}
      >
        <AccountCreateForm handleClose={handleCloseCreate} />
      </Dialog>

      <Backdrop className={classes.backdrop} open={accountsLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Dialog
        scroll="paper"
        onClose={handleCloseCreate}
        aria-labelledby="customized-dialog-title"
        open={openUpdate}
        maxWidth="md"
        fullWidth={true}
      >
        <AccountUpdateForm
          account={updatingItem}
          handleClose={handleCloseUpdate}
        />
      </Dialog>

      <Backdrop className={classes.backdrop} open={accountsLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Fab
        color="primary"
        aria-label="add"
        className={classes.buttonCreate}
        onClick={handleOpenCreate}
      >
        <AddIcon />
      </Fab>
    </>
  );
};
